var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"tableIndividuals",staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.individuals,"sort-by":"last_name","loading":_vm.individualIsBusy,"search":_vm.search,"show-expand":""},on:{"dblclick:row":_vm.view_onClick},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" No "+_vm._s(_vm.displayAsPlural)+" Available ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('h3',{staticClass:"primary--text"},[_vm._v(" No matching "+_vm._s(_vm.displayAsSingular)+" data found containing '"+_vm._s(_vm.search)+"' ")])]},proxy:true},{key:"loading",fn:function(){return [_c('h2',{staticClass:"primary--text"},[_vm._v(" Loading "+_vm._s(_vm.displayAsPlural)+"... Please wait ")])]},proxy:true},{key:"top",fn:function(){return [_c('v-container',{staticClass:"pa-0 ma-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"8"}},[_c('h2',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(_vm.displayAsPlural)+" ")])]),_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"2"}})],1),_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 ma-0 text-center",attrs:{"cols":"12","sm":"10"}},[(_vm.getLengthOf(_vm.individuals) > 0)?_c('v-text-field',{staticClass:"pl-10 pr-10",attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.getLengthOf(item.guid) > 0)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.view_onClick($event, { item: item })}}},[_vm._v(" mdi-file-eye ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pt-4 pb-4",attrs:{"colspan":_vm.getLengthOf(_vm.headers) + 1}},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.detailHeaders,"items":[item],"hide-default-footer":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('h2',{staticClass:"primary--text font-weight-bold text-center pl-1 pt-2 pr-1"},[_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" - "+_vm._s(_vm.displayAsSingular)+" Details ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"primary--text font-weight-bold text-body-1 text-center",attrs:{"cols":"12","sm":"6"}},[_vm._v(" Step 1 ")]),_c('v-col',{staticClass:"primary--text font-weight-bold text-body-1 text-center",attrs:{"cols":"12","sm":"6"}},[_vm._v(" Step 2 ")])],1)]},proxy:true},{key:"header.step_1_revised_deadline_date",fn:function(ref){
var header = ref.header;
return [_c('v-col',{staticClass:"primary--text font-weight-bold text-body-1 text-center step-border",attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(header.text))])])]}},{key:"item.step_1_revised_deadline_date",fn:function(ref){
var item = ref.item;
return [_c('v-col',{staticClass:"primary--text font-weight-bold text-body-1 text-center step-border",attrs:{"cols":"12","sm":"12"}},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(item.step_1_revised_deadline_date))])])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }