export default {
  required: (value) => !!value || 'Required.',
  isPrimaryId: (value) => {
    const pattern = /^[a-zA-Z0-9-_]+$/

    return pattern.test(value) || 'Invalid primary id.'
  },
  noSpaces: (value) => {
    const pattern = /^[^\s-]+$/

    return pattern.test(value) || 'Must not contain spaces.'
  },
  isNumeric: (value) => {
    let isValid = true

    if (value !== null) {
      if (value.length > 0) {
        const pattern = /^[0-9]+$/

        isValid = pattern.test(value)
      }
    }

    return isValid || 'Must be numeric.'
  },
  isGreaterThanZero: (value) => {
    let isValid = true
    let errorMsg = 'Must be greater than zero.'

    if (value !== null) {
      if (value.length > 0) {
        const pattern = /^[0-9]+$/

        isValid = pattern.test(value)

        if (isValid) {
          isValid = parseInt(value, 0) > 0
        } else {
          errorMsg = 'Must be numeric.'
        }
      }
    }

    return isValid || errorMsg
  },
  validTaxId: (value) => {
    const patternEIN = /^(?![01][789]|2[89]|[46]9|7[089]|89|9[67])\d\d-\d{7}$/
    const patterSSN = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/

    const isValidEIN = patternEIN.test(value)
    const isValidSSN = patterSSN.test(value)

    return (isValidEIN || isValidSSN) || 'Invalid tax id. (Use dashes 111-22-3333 or 12-1234567)'
  },
  validEmail: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    return pattern.test(value) || 'Invalid e-mail.'
  },
  validAddress1: (value) => {
    let isValid = true

    // TODO: How do you validate Address 1? regex for Address 1?
    isValid = true

    return isValid || 'Invalid Address 1.'
  },
  validCity: (value) => {
    let isValid = true

    if (value) {
      if (value.length > 0) {
        const pattern = /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/

        isValid = pattern.test(value)
      }
    }

    return isValid || 'Invalid City.'
  },
  validState: (value) => {
    let isValid = true

    if (value) {
      if (value.length > 0) {
        const pattern = /^(([Aa][EeLlKkSsZzRr])|([Cc][AaOoTt])|([Dd][EeCc])|([Ff][MmLl])|([Gg][AaUu])|([Hh][Ii])|([Ii][DdLlNnAa])|([Kk][SsYy])|([Ll][Aa])|([Mm][EeHhDdAaIiNnSsOoTt])|([Nn][EeVvHhJjMmYyCcDd])|([Mm][Pp])|([Oo][HhKkRr])|([Pp][WwAaRr])|([Rr][Ii])|([Ss][CcDd])|([Tt][NnXx])|([Uu][Tt])|([Vv][TtIiAa])|([Ww][AaVvIiYy]))$/

        isValid = pattern.test(value)
      }
    }

    return isValid || 'Invalid U.S. State.'
  },
  validZip: (value) => {
    let isValid = true

    if (value) {
      if (value.length > 0) {
        const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/

        isValid = pattern.test(value)
      }
    }

    return isValid || 'Invalid U.S. Zip Code.'
  },
  validPhone: (value) => {
    let isValid = true

    if (value) {
      if (value.length > 0) {
        const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/

        isValid = pattern.test(value)
      }
    }

    return isValid || 'Invalid U.S. Phone Number.'
  },
  compareTimes (time1, time2, errorMessage = 'Invalid time.') {
    let validTime = true

    if (time1 && time2) {
      let parsedTime1 = 0
      let parsedTime2 = 0

      if (time1.length > 0 && time1.toString().includes(':') &&
          time2.length > 0 && time2.toString().includes(':')) {
        parsedTime1 = time1.split(':')[0] * 3600 + time1.split(':')[1] * 60
        parsedTime2 = time2.split(':')[0] * 3600 + time2.split(':')[1] * 60

        if (parsedTime1 >= parsedTime2) {
          validTime = false
        }
      }
    }

    return validTime || errorMessage
  },
  isCurrency (value, errorMessage = 'Invalid Currency Value.') {
    let validCurrency = true

    if (value && value.length > 0) {
      const pattern = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/

      validCurrency = pattern.test(value)
    }

    return validCurrency || errorMessage
  },
  validOption (selectedOption, availableOptions, errorMessage = 'Option does not exist in the list.') {
    let valid = true

    if (selectedOption) {
      let selectedDescription = ''

      if ({}.hasOwnProperty.call(selectedOption, 'description')) {
        selectedDescription = selectedOption.description
      } else {
        selectedDescription = selectedOption
      }

      if (selectedDescription.length > 0) {
        valid = false
        for (const thisOption of availableOptions) {
          if (thisOption.description === selectedDescription) {
            valid = true
            break
          }
        }
      }
    }

    if (valid) {
      return valid
    } else {
      return errorMessage
    }
  },
  imageSize (image, imageType = 'Image') {
    return !image || image.size < 2000000 || `${imageType} size should be less than 2 MB!`
  }
}
