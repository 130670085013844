<template>
  <v-sheet color="transparent">
    <ToolbarMaster />
    <Contact />
    <FooterMaster />
  </v-sheet>
</template>

<script>
import ToolbarMaster from '@/components/ui/ierp/toolbar/ToolbarMaster'
import Contact from '@/components/ui/ierp/pages/Contact.vue'
import FooterMaster from '@/components/ui/ierp/pages/FooterMaster.vue'

export default {
  name:'ContactPage',
  components: {
    ToolbarMaster,
    Contact,
    FooterMaster
  }
}
</script>
