<template>
  <v-footer color="transparent">
    <v-container>
      <v-row>
        <!-- =============================================================================================== -->
        <!-- Contact Information                                                                             -->
        <!-- =============================================================================================== -->
        <v-col cols="12" md="6">
          <div class="text-h6 text-lg-h5 font-weight-bold">Contact Information</div>
          <div style="width: 200px; height: 3px" class="mb-3 mt-1 secondary" />
          <v-list
            class="ma-0 pa-0"
            style="background-color: transparent!important;"
            dense
          >
            <!-- Map Link -->
            <v-list-item
              href="https://www.google.com/maps/place/1121+Park+W+Blvd,+Mt+Pleasant,+SC+29466/@32.8733481,-79.7814206,17z/data=!3m1!4b1!4m5!3m4!1s0x88fe6d05fff029c3:0xa3351e795d9bb66!8m2!3d32.8733481!4d-79.7792319"
              target="_blank"
              class="ma-0 pa-0 mb-1"
              dense
            >
              <v-list-item-icon>
                <v-icon color="secondary lighten-1">mdi-map-marker-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content class="ma-0 pa-0 font-weight-bold">
                {{ config.product.name }}<br>
                {{ config.product.mailing_address_1 }}, {{ config.product.mailing_address_2 }}<br>
                {{ config.product.mailing_city }}, {{ config.product.mailing_state_cd }} {{ config.product.mailing_zip }}
              </v-list-item-content>
            </v-list-item>
            <!-- Phone Link -->
            <v-list-item
              href="tel:+18434394657"
              class="ma-0 pa-0 mb-1"
              dense
            >
              <v-list-item-icon>
                <v-icon color="secondary lighten-1">mdi-phone-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content class="ma-0 pa-0 font-weight-bold">
                843-439-4657
              </v-list-item-content>
            </v-list-item>
            <!-- Email Link -->
            <v-list-item
              :href="`mailto:${config.product.email_claims}?subject=${email_subject}`"
              target="_blank"
              class="ma-0 pa-0"
              dense
            >
              <v-list-item-icon>
                <v-icon color="secondary lighten-1">mdi-email-outline</v-icon>
              </v-list-item-icon>

              <v-list-item-content class="ma-0 pa-0">
                {{ config.product.email_claims }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <!-- =============================================================================================== -->
        <!-- Navigation                                                                                      -->
        <!-- =============================================================================================== -->
        <v-col cols="12" md="6">
          <div class="text-h6 text-lg-h5 font-weight-bold">Navigation</div>
          <div style="width: 105px; height: 3px" class="mb-3 mt-1 secondary" />
          <div class="d-flex flex-wrap">
            <div v-for="(link, i) in links" :key="i" class="w-half body-1 mb-1">
              <router-link
                v-if="link.to"
                class="text-decoration-none secondary--text text--lighten-2"
                :to="link.to"
              >{{ link.label }}</router-link>
              <a
                v-else
                class="text-decoration-none secondary--text text--lighten-2"
                :href="link.href"
                :target="link.target || 'blank'"
              >{{ link.label }}</a>
            </div>
          </div>
        </v-col>

      </v-row>
      <v-divider class="my-3"></v-divider>
      <div class="text-center caption">
        © SimLow Software 2022. All Rights Reserved
      </div>
    </v-container>
  </v-footer>
</template>

<script>
import config from '@/configs'

export default {
  data() {
    return {
      config,
      email_subject: 'Contact IERP Claims Admin',
      links: [{
        label: 'Home',
        to: '/HomeMaster'
      }, {
        label: 'Documents',
        to: '/DocumentsMaster'
      }, {
        label: 'FAQ',
        to: '/FaqMaster'
      }, {
        label: 'Contact',
        to: '/ContactMaster'
      }]
    }
  }
}
</script>
