<template>
  <v-form v-model="formIsValid">
    <v-simple-table
      class="elevation-1 d-print-none"
      dense
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="header in distribution_headers"
              :key="header.value"
              :class="header.class"
              :style="`text-align: ${header.align}; width: ${header.width};`"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in distribution_amounts"
            :key="item.year"
          >
            <td :style="`text-align: ${distribution_headers[0].align};`">
              <div class="font-weight-bold">{{ item.year }}</div>
            </td>
            <td :style="`text-align: ${distribution_headers[1].align};`">
              <v-checkbox
                v-model="item.selected"
                label="Worked"
                class="pa-0 ma-0 pt-1"
                :disabled="displayOnly"
                dense
                @change="selected_onChange(item)"
              />
              <v-checkbox
                v-model="item.proof"
                label="Proof Attached"
                class="pa-0 ma-0"
                dense
                disabled
                @change="proof_onChange(item)"
              />
            </td>
            <td :style="`text-align: ${distribution_headers[2].align};`">
              <v-text-field
                v-model="item.hours"
                style="width: 100px;"
                :disabled="displayOnly"
                :rules="[config.rules.isGreaterThanZero]"
                :hint="`${item.year} hours`"
                persistent-hint
                dense
                @blur="hours_onBlur(item)"
              />
            </td>
            <td
              class="pt-2"
              :style="`text-align: ${distribution_headers[3].align};`"
            >
              <v-file-input
                v-if="!displayOnly"
                :label="`Proof for ${item.year}`"
                :key="item.documents_count"
                prepend-icon="mdi-file-document-multiple"
                class="pa-0 ma-0"
                hint="(1099, contract, etc.)"
                persistent-hint
                dense
                show-size
                multiple
                @change="uploadProof($event, item)"
                @progress="proof_onProgress"
              />
              <div
                v-else
                class="pl-2 font-weight-bold"
              >
                <span
                  v-if="item.hours > 0"
                >
                  {{ (item.documents_count > 0 ? `Proof for ${item.year} uploaded` : `Proof for ${item.year} Not Uploaded`) }}
                </span>
              </div>

              <div
                class="ma-0 pa-0 pt-1 pl-4"
              >
                <list-documents
                  :v-model="item"
                  label=""
                  :value="item"
                  :key="item.documents_count"
                  :path="getPath(item)"
                  @emptied="documents_onEmpty(item)"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th class="text-left font-weight-bold text-body-1">Totals:</th>
            <td class="text-left font-weight-bold text-body-1">{{ totalYears }} Years</td>
            <td class="text-left font-weight-bold text-body-1">{{ totalHours }} Hours</td>
            <td class="text-left font-weight-bold text-body-1"></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/configs'
import { Storage } from 'aws-amplify'
import ListDocuments from '@/components/ui/ierp/components/controls/ListDocuments'
import { ClaimStatus } from '@/models'

export default {
  name: 'DistributionAmountsTable',

  components: {
    ListDocuments
  },

  data () {
    return {
      config,
      formIsValid: false
    }
  },

  computed: {
    ...mapGetters({
      totalYears: 'abatementDistribution/getTotalYears',
      totalHours: 'abatementDistribution/getTotalHours',
      yearsAmount: 'abatementDistribution/getYearsAmount'
    }),
    users () {
      return this.$store.state.user.users
    },
    distribution_headers () {
      return this.$store.state.abatementDistribution.headers
    },
    distribution_amounts () {
      return this.$store.state.abatementDistribution.updates.amounts
    },
    originalData () {
      return this.$store.state.abatementDistribution.data
    },
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  methods: {
    hasError () {
      let isError = true

      if (this.totalYears > 0 && this.totalHours > 0) {
        isError = !this.formIsValid
      }

      return isError
    },

    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)

        this.$store.dispatch('abatementDistribution/update')
      })
    },

    selected_onChange (item) {
      if (item.selected) {
        item.hours = 0
      } else {
        if (item.proof) {
          item.proof = false
        }
        item.hours = null
      }

      this.onChange(item)
    },

    proof_onChange (item) {
      if (item.proof) {
        item.selected = true
        if (!item.hours) {
          item.hours = 0
        }
      }

      this.onChange(item)
    },

    async hours_onBlur (item) {
      const originalAmount = await this.yearsAmount(item.year, this.originalData.amounts)

      if (item.hours) {
        if (item.hours > 0) {
          item.selected = true
        }
      }

      if (originalAmount !== null) {
        if (item.hours !== null && originalAmount.hours !== null) {
          if (item.hours.toString() !== originalAmount.hours.toString()) {
            this.onChange(item)
          }
        }
      }
    },

    async putFile (docPath, currentFile, item) {
      await Storage.put(`${docPath}/${currentFile.name}`, currentFile)
        .then((result) => {
          console.info(`File '${currentFile.name}' uploaded successfully`)
          this.$store.dispatch('abatementDistribution/incrementDocCount', item)
        })
        .catch((err) => {
          // TODO: Gracefully display errors to the user
          console.error(err)
          // this.$store.dispatch('error/setError', { name: 'Uploading Document', details: err })
        })
    },

    getPath (item) {
      return `user/${this.users.guid}/${item.year}`
    },

    documents_onEmpty (item) {
      this.onChange(item)
    },

    uploadFiles (uploadedFiles, item) {
      const docPath = this.getPath(item)

      if (docPath.length > 0) {
        for (const currentFile of uploadedFiles) {
          this.putFile(docPath, currentFile, item)
        }
      }
    },

    uploadProof (uploadedFiles, item) {
      if (uploadedFiles) {
        if (uploadedFiles.length > 0) {
          const filesToUpload = [...uploadedFiles]

          if (item.proof !== true) {
            item.proof = true
            this.proof_onChange(item)
          }

          this.uploadFiles(filesToUpload, item)
        }
      }
    },

    proof_onProgress (item) {
      this.$store.dispatch('abatementDistribution/incrementDocCount', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
