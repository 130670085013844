<template>
  <div class="ma-0 pa-0">
    <div
      :class="(displayOnly ? 'd-inline-block' : 'print-only')"
    >
      <span class="font-weight-bold">{{ (label.length > 0 ? `${label}:` : '') }}</span>
      <span class="pl-1">{{ internalValue }}</span>
      <v-divider class="mb-1" />
    </div>
    <div
      v-if="!displayOnly"
      class="d-print-none"
    >
      <v-container
        class="pa-0 ma-0"
      >
        <v-row
          no-gutters
          :class="(hasError() ? 'mb-2' : null)"
        >
          <v-col
            cols="12"
            :sm="cols"
          >
            <v-text-field
              ref="textField"
              v-model="internalValue"
              :type="type"
              :rules="prepRules()"
              :maxlength="maxlength"
              :hint="(hint ? `${hint}` : null)"
              :persistent-hint="(hint ? true : false)"
              clearable
              dense
              @blur="onBlur(internalValue)"
              @change="onChange(internalValue)"
            >
              <template v-slot:label>
                <v-icon
                  v-if="required"
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                {{ label }}
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import config from '@/configs'
import { ClaimStatus } from '@/models'

export default {
  name: 'PrintableTextField',

  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: 'Label',
      required: true
    },
    cols: {
      type: String,
      default: '12'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: String,
      default: '50'
    },
    minWidth: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    },
    rules: {
      type: Array
    }
  },

  data () {
    return {
      internalValue: this.value,
      config
    }
  },

  computed: {
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  watch: {
    internalValue: {
      handler (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', this.internalValue)
      }
    },
    value: {
      handler (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      }
    }
  },

  methods: {
    hasError () {
      let isError = false

      if (!isError && this.$refs.textField) {
        isError = this.$refs.textField.hasError
      }

      return isError
    },

    onBlur (dataItem) {
      this.$nextTick(() => {
        this.$emit('blur', dataItem)
      })
    },

    onChange (dataItem) {
      this.$nextTick(() => {
        this.$emit('change', dataItem)
      })
    },

    prepRules () {
      const allRules = []

      if (this.required && !this.disabled) {
        allRules.push(this.config.rules.required)
      }

      if (this.rules) {
        for (const parentRule of this.rules) {
          allRules.push(parentRule)
        }
      }

      return allRules
    }
  }
}
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
