<template>
  <Login />
</template>

<script>
import Login from '@/components/ui/ierp/pages/Login.vue'

export default {
  components: {
    Login
  }
}
</script>
