import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import AppModule from './app'
import UserModule from './user'
import AbatementDistributionModule from './abatementDistribution'
import IndividualModule from './individual'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    user: UserModule,
    abatementDistribution: AbatementDistributionModule,
    individual: IndividualModule
  }
})

store.dispatch('app/init')

export default store
