<template>
  <v-container
    v-if="getLengthOf(docList) > 0"
    class="ma-0 pa-0"
  >
    <v-row
      no-gutters
    >
      <v-col
        v-if="label.length > 0"
        cols="12"
        sm="2"
        class="primary--text text-body-1 pr-2 font-weight-bold"
      >
        {{ label }}:
      </v-col>

      <v-col
        cols="12"
        sm="10"
        class="font-weight-medium text-body-1 text-left"
      >
        <div
          v-for="file in docList"
          :key="file.key"
          class="pb-1"
        >
          <v-icon
            v-if="!displayOnly"
            small
            color="secondary"
            class="d-inline-block pr-1"
            title="Delete"
            @click="removeDoc_onClick(file)"
          >
            mdi-delete
          </v-icon>

          <a
            :href="file.url"
            color="secondary"
            class="d-inline-block"
            title="Download"
          >
            {{ file.fileName }}
          </a>
        </div>

        <confirmation-dialog
          ref="removeDocConfirmationDialog"
          @confirm="confirm_onClick"
        >
          <template #title>
            <h2 class="primary--text ma-0 pa-0">
              Confirm Remove Document
            </h2>
          </template>

          <template #message>
            <div class="ma-0 pa-0 pt-2">
              Are you sure you want to remove the
              <span class="primary--text">{{ ( removeFile ? removeFile.fileName : '' ) }}</span>
              document?
            </div>
          </template>
        </confirmation-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Storage } from 'aws-amplify'
import ConfirmationDialog from '@/components/ui/ierp/components/controls/ConfirmationDialog'
import { ClaimStatus } from '@/models'

export default {
  name: 'ListDocuments',

  components: {
    ConfirmationDialog
  },

  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null,
      required: true
    },
    path: {
      type: String,
      default: null,
      required: true
    }
  },

  data () {
    return {
      internalValue: this.value,
      docList: [],
      removeFile: null
    }
  },

  computed: {
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  watch: {
    internalValue: {
      handler (val, oldVal) {
        if (val.id === oldVal.id) return

        this.$emit('input', this.internalValue)
      },
      deep: true
    },
    value: {
      handler (val, oldVal) {
        if (val.id === oldVal.id) return

        this.internalValue = val
      },
      deep: true
    }
  },

  created () {
    this.listDocuments(this.internalValue)
  },

  methods: {
    onEmpty (dataItem) {
      this.$nextTick(() => {
        this.$emit('emptied', dataItem)
      })
    },

    async setFileUrl (currentFile) {
      const fileUrl = await Storage.get(currentFile.key)

      for (const doc of this.docList) {
        if (currentFile.key === doc.key) {
          doc.url = fileUrl
        }
      }
    },

    async listDocuments (item) {
      const docPath = this.path

      await Storage.list(docPath)
        .then((result) => {
          this.docList = []

          for (const currentFile of result) {
            this.setFileUrl(currentFile)

            this.docList.push({
              key: currentFile.key,
              url: null,
              fileName: currentFile.key.toString().replace(`${docPath}/`, '')
            })

            this.$emit('progress', item)
          }

          this.internalValue.documents_count = this.docList.length
        })
        .catch((err) => {
          // TODO: Gracefully display errors to the user
          console.error(err)
          // this.$store.dispatch('error/setError', { name: 'Uploading Document', details: err })
          this.docList = []
        })
    },

    removeDoc_onClick (thisFile) {
      this.removeFile = thisFile

      this.$refs.removeDocConfirmationDialog.setDisplayDialog(true)
    },

    async confirm_onClick () {
      const { fileName } = this.removeFile

      await Storage.remove(this.removeFile.key)
        .then(() => {
          const showToast = {
            title: 'Document Removed',
            messages: [`Document '${fileName}' successfully removed.`],
            variant: 'success'
          }

          this.$store.dispatch('user/showToast', showToast)
          this.listDocuments(this.internalValue)
        })
        .then(() => {
          // If we have 1 or less documents when removing a doc, proof should = false
          if (this.internalValue.documents_count <= 1) {
            this.internalValue.proof = false
            this.onEmpty(this.internalValue)
          }
        })
        .catch((err) => {
          // TODO: Gracefully display errors to the user
          console.error(err)
          // this.$store.dispatch('error/setError', { name: 'Removing Document', details: err })
        })
    }
  }
}
</script>

<style scoped>

</style>
