import mutations from './mutations'
import actions from './actions'

// state initial values
const state = {
  displayAsSingular: 'Claimant',
  displayAsPlural: 'Claimants',
  lastReload: {
    datetime: null,
    thresholdSeconds: 2
  },
  data: {},
  all: [],
  isBusy: false
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
