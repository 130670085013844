import LandingMaster from '@/components/ui/ierp/landing/LandingMaster'
import HomeMaster from '@/components/ui/ierp/pages/HomeMaster.vue'
import DocumentsMaster from '@/components/ui/ierp/pages/DocumentsMaster.vue'
import ContactMaster from '@/components/ui/ierp/pages/ContactMaster.vue'
import FaqMaster from '@/components/ui/ierp/pages/FaqMaster.vue'
import LoginMaster from '@/components/ui/ierp/pages/LoginMaster.vue'
import AbatementDistribution from '@/components/ui/ierp/pages/protected/AbatementDistribution'
import AdminView from '@/components/ui/ierp/pages/protected/AdminView'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      // Default start page
      path: '/',
      name: 'landing',
      component: LandingMaster
    },
    {
      path: '/HomeMaster',
      name: 'home',
      component: HomeMaster
    },
    {
      path: '/DocumentsMaster',
      name: 'documents',
      component: DocumentsMaster
    },
    {
      path: '/FaqMaster',
      name: 'faq',
      component: FaqMaster
    },
    {
      path: '/ContactMaster',
      name: 'contact',
      component: ContactMaster
    },
    {
      path: '/LoginMaster',
      name: 'login',
      component: LoginMaster
    },
    {
      path: '/protected/AbatementDistribution',
      name: 'Abatement Distribution Form',
      component: AbatementDistribution
    },
    {
      path: '/protected/AdminView',
      name: 'Admin View',
      component: AdminView
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})
