<template>
  <v-sheet color="transparent">
    <ToolbarMaster />
    <Documents
      :docs="doc"
    />
    <FooterMaster />
  </v-sheet>
</template>

<script>
import ToolbarMaster from '@/components/ui/ierp/toolbar/ToolbarMaster'
import Documents from '@/components/ui/ierp/pages/Documents.vue'
import FooterMaster from '@/components/ui/ierp/pages/FooterMaster.vue'

export default {
  components: {
    ToolbarMaster,
    Documents,
    FooterMaster
  },
  data() {
    return {
      doc: [{
        link: '1: Mallinckrodt 4th Amended Plan',
        href: 'https://ierp-documents.s3.amazonaws.com/Mallinckrodt+4th+Amended+Plan.pdf'
      }, {
        link: '2: IERP Trust',
        href: 'https://ierp-documents.s3.amazonaws.com/IERP+Trust+Agreement.pdf'
      }, {
        link: '3: Notice of Possible Eligibility for Abatement Distribution Award - STEP 1',
        href: 'https://ierp-documents.s3.amazonaws.com/Notice+of+Possible+Eligibility+for+Abatement+Distribution+Award+-+STEP+ONE.pdf'
      }, {
        link: '4: IER Physicians Abatement Distribution Form - STEP 2',
        href: 'https://ierp-documents.s3.amazonaws.com/IER+Physicians+Abatement+Distribution+Form.pdf'
      },{
        link: '5: IERP Trust Authorized Recipient’s Instruction and Agreement Form',
        href: 'https://ierp-documents.s3.amazonaws.com/IERP+Authorized+Recipient+Instruction+and+Agreement+form.pdf'
      },{
        link: '6: IERP Trust Authorized Abatement Purposes',
        href: 'https://ierp-documents.s3.amazonaws.com/Authorized+Abatement+Purposes.pdf'
      },{
        link: '7: Order Confirming Joint Amended Plan of Reorganization',
        href: 'https://ierp-documents.s3.amazonaws.com/Order+Confirming+Joint+Amended+Plan+of+Reorganization.pdf'
      },{
        link: '8: IERP Trust Distribution Procedures',
        href: 'https://ierp-documents.s3.amazonaws.com/IERP+Trust+Distribution+Procedures.pdf'
      },{
        link: '9: Mallinckrodt Bankruptcy Case Docket',
        href: 'https://restructuring.ra.kroll.com/mallinckrodt/Home-DocketInfo'
      }]
    }
  }
}
</script>
