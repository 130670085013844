<template>
  <v-sheet color="transparent">
    <ToolbarMaster />
    <Home />
    <FooterMaster />
  </v-sheet>
</template>

<script>
import ToolbarMaster from '@/components/ui/ierp/toolbar/ToolbarMaster.vue'
import Home from '@/components/ui/ierp/pages/Home.vue'
import FooterMaster from '@/components/ui/ierp/pages/FooterMaster.vue'

export default {
  components: {
    ToolbarMaster,
    Home,
    FooterMaster
  }
}
</script>
