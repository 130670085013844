<template>
  <Landing />
</template>

<script>
import Landing from '@/components/ui/ierp/landing/Landing.vue'

export default {
  components: {
    Landing
  }
}
</script>
