export default {
  getYearsAmount: () => (amountYear, amounts) => {
    let yearsAmount

    for (const currentData of amounts) {
      if (parseInt(currentData.year, 0) === parseInt(amountYear, 0)) {
        yearsAmount = currentData
      }
    }

    return yearsAmount
  },

  getTotalYears: (state) => {
    let total = 0

    for (const currentData of state.updates.amounts) {
      if (currentData.selected) total++
    }

    return total
  },

  getTotalHours: (state) => {
    let total = 0

    for (const currentData of state.updates.amounts) {
      if (currentData.hours) {
        if (currentData.selected) total += parseInt(currentData.hours, 0)
      }
    }

    return total
  },

  getAmountsWithNoProof: (state) => {
    const amounts = []
    const cols_per_row = 4
    const col_width = `${100 / cols_per_row}%`
    let col_count = 0
    let columns = []

    for (const currentData of state.updates.amounts) {
      if (currentData.selected && !currentData.proof) {
        col_count++

        if (col_count > cols_per_row) {
          amounts.push([...columns])
          col_count = 1
          columns = []
        }

        columns.push({ year: currentData.year, hours: currentData.hours, width: col_width })
      }
    }

    if (columns.length > 0) {
      amounts.push([...columns])
    }

    return amounts
  }
}
