<template>
  <v-form v-model="formIsValid">
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <div id="attachment-B" class="font-italic pb-1">
          Attachment B
        </div>
      </v-col>
    </v-row>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <h2 class="font-weight-bold text-center">AFFIDAVIT</h2>
      </v-col>
    </v-row>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <h3 class="text-center">(SWORN STATEMENT)</h3>
      </v-col>
    </v-row>
    <!-- Date Field -->
    <v-row
      no-gutters
      class="d-print-block pt-4"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="2"
        class="text-left"
      >
        <printable-date-field
          v-model="abatementDistributions.affidavit.date"
          label="Date"
          @change="onChange"
        />
      </v-col>
    </v-row>
    <!-- Name Field -->
    <v-row
      no-gutters
      class="d-print-block pt-2"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <printable-text-field
          ref="affidavitName"
          v-model="abatementDistributions.affidavit.name"
          label="My Legal name is"
          cols="8"
          @blur="textField_onBlur($event, (originalData.affidavit ? originalData.affidavit.name : null))"
        />
      </v-col>
    </v-row>
    <!-- Address Field -->
    <v-row
      no-gutters
      class="d-print-block pt-1"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <address-block
          ref="affidavitAddress"
          v-model="abatementDistributions.affidavit"
          @blur="address_onBlur(abatementDistributions.affidavit, originalData.affidavit)"
        />
      </v-col>
    </v-row>
    <!-- Under oath -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <div class="pt-1">
          [STATE]
        </div>
        <div class="pt-3">
          I hereby swear under oath that I am over 18 years of age and have personal knowledge of the following:
        </div>
        <ol
          type="1"
          class="ma-0 pa-0 pl-2"
        >
          <li class="pt-1">
            I understand independent emergency room physician to mean that I was not an employee for
            the Hospital for which I was working in that emergency room.
            I worked at the Hospital as an Independent Contractor.
          </li>
          <li class="pt-1">
            I worked as an independent emergency room physician for some time during the years 2001-2019.
          </li>
          <li class="pt-1">
            I worked as an independent emergency room physician for the following number of
            hours in each the following years indicated (years must be between 2001 – 2019):
          </li>
        </ol>
        <div class="pt-3 pb-1">
          Record each year worked as an Independent Emergency Room Physician and the number of hours worked for each year
          <span class="font-weight-bold">
            only if you are unable to find proof of either working as an IERP
            (1099 or contracts) and/or for the number of hours worked each year
          </span>
          (work schedules or pay records).
        </div>

        <div
          v-for="(row, index) in distribution_no_proof"
          :key="index"
          style="width: 100%"
        >
          <div
            v-for="col in row"
            :key="col.year"
            class="d-inline-block"
            :style="`width:${col.width}`"
          >
            {{ col.year }}: {{ col.hours }} hours
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <ol
          type="1"
          start="4"
          class="ma-0 pa-0 pl-2"
        >
          <li class="pt-16">
            I certify that for each year and/or hours identified above in which I could not find proof of
            either working as an IERP and/or the number of hours worked,
            I have described, to the best of my ability,
            in detail what I did and the effort I made to attempt to find such proof and
            an explanation of why I cannot provide such proof.
            I have attached that explanation to this affidavit.
          </li>
        </ol>
      </v-col>
    </v-row>
    <!-- Page Break Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        &nbsp;
      </v-col>
    </v-row>
    <!-- New Page: Attachment B Signature -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <ol
          type="1"
          start="5"
          class="ma-0 pa-0 pl-2"
        >
          <li class="pt-1">
            I made a good faith effort to locate documents and proof that I worked as an
            Independent Emergency Room Physician for all the years and for the hours identified above,
            but I was not able to locate documents for the years identified above.
          </li>
        </ol>
        <div class="pt-4">
          Under penalty of perjury, I hereby declare and affirm that the above-mentioned statement and the attachment hereto is, to the best of my knowledge, true and correct.
        </div>
        <div class="pt-4">
          <signature-line
            :value="abatementDistributions.affidavit.signature"
            label="Affiant's Signature:"
            @change="affiantSignature_onChange"
          />
        </div>
        <div class="pt-2 font-weight-bold text-center">
          NOTARY ACKNOWLEDGEMENT
        </div>
        <div class="pa-1 mt-2 print-table">
          A notary public or other officer completing this certificate verifies only the identity of the
          individual who signed the document to which this certificate is attached,
          and not the truthfulness, accuracy, or validity of that document.
        </div>
        <div class="pt-2">
          State of:
        </div>
        <div>
          County of:
        </div>
        <div class="pt-2">
          On ___________________ (date) before me _____________________________________________________
          (affiant’s name), personally appeared and who proved to me on the basis of satisfactory evidence to
          be the person whose name is subscribed to the within instrument and acknowledged to me that they
          executed the same in their authorized capacity, and that by their signature on the instrument the
          person(s), or the entity upon behalf of which the person(s) acted, executed the instrument.
        </div>
        <div class="pt-2">
          I certify under PENALTY OF PERJURY under the laws of the State of ___________________
          that the foregoing paragraph is true and correct.
        </div>
        <div class="pt-2">
          WITNESS my hand and official seal.
        </div>
        <div class="pt-4">
          <signature-line
            label="Signature:"
            :display-date="false"
            :confirmation-checkbox="false"
            :required="false"
          />
          <span class="d-inline-block pl-6 print-only">(Seal)</span>
        </div>
      </v-col>
    </v-row>
    <!-- Instructions -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1"
      >
        <div
          class="pt-2 d-print-none"
        >
          <v-file-input
            v-if="!displayOnly"
            :key="fileNotarized.documents_count"
            :label="(fileNotarized.documents_count > 0 ? 'Notarized Copy uploaded' : 'Upload your Notarized Copy here')"
            accept=".pdf"
            prepend-icon="mdi-pdf-box"
            class="pt-1"
            :disabled="fileNotarized.documents_count > 0"
            dense
            show-size
            @change="uploadNotarized"
          />
          <div
            v-else
            class="pl-2 font-weight-bold"
          >
            {{ (fileNotarized.documents_count > 0 ? 'Notarized Copy uploaded' : 'Notarized Copy Not Uploaded') }}
          </div>
        </div>

        <div
          class="ma-0 pa-0 pl-4 d-print-none"
        >
          <list-documents
            :v-model="fileNotarized"
            label=""
            :value="fileNotarized"
            :key="fileNotarized.documents_count"
            :path="getPath()"
          />
        </div>

        <div class="font-weight-bold pt-4">
          If you cannot provide the required information, please print and complete with notary.
          <span class="print-only">
            The completed form can be mailed to:
          </span>
          <span class="d-print-none">
            The completed form can either be scanned and loaded using the field above or it can be mailed to:
          </span>
        </div>
        <div style="display: block;" class="pt-2 pl-1">Claims Administrator,</div>
        <div style="display: block;" class="pl-1">{{ config.product.name }}, {{ config.product.mailing_address_1 }}</div>
        <div style="display: block;" class="pl-1">{{ config.product.mailing_address_2 }}</div>
        <div style="display: block;" class="pl-1">{{ config.product.mailing_city }}, {{ config.product.mailing_state_cd }} {{ config.product.mailing_zip }}</div>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { Storage } from 'aws-amplify'
import config from '@/configs'
import PrintableDateField from '@/components/ui/ierp/components/controls/PrintableDateField'
import PrintableTextField from '@/components/ui/ierp/components/controls/PrintableTextField'
import AddressBlock from '@/components/ui/ierp/components/controls/AddressBlock'
import SignatureLine from '@/components/ui/ierp/components/controls/SignatureLine'
import ListDocuments from '@/components/ui/ierp/components/controls/ListDocuments'
import { ClaimStatus } from '@/models'

export default {
  name: 'AttachmentB',

  components: {
    PrintableDateField,
    PrintableTextField,
    AddressBlock,
    SignatureLine,
    ListDocuments
  },

  data: () => ({
    formIsValid: false,
    fileNotarized: { documents_count: 0 },
    config
  }),

  computed: {
    ...mapGetters({
      distribution_no_proof: 'abatementDistribution/getAmountsWithNoProof'
    }),
    users () {
      return this.$store.state.user.users
    },
    abatementDistributions () {
      return this.$store.state.abatementDistribution.updates
    },
    originalData () {
      return this.$store.state.abatementDistribution.data
    },
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  methods: {
    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)
        this.$store.dispatch('abatementDistribution/update')
      })
    },

    textField_onBlur (value, originalData) {
      if (value !== null && originalData !== null) {
        if (value.toString().trim() !== originalData.toString().trim()) {
          this.onChange(value)
        }
      }
    },

    address_onBlur (updatedData, originalData) {
      if (updatedData.address1 !== originalData.address1 ||
        updatedData.address2 !== originalData.address2 ||
        updatedData.city !== originalData.city ||
        updatedData.state !== originalData.state ||
        updatedData.zip !== originalData.zip) {
        this.onChange(updatedData)
      }
    },

    affiantSignature_onChange (evt) {
      this.abatementDistributions.affidavit.signature = evt
      this.onChange(evt)
    },

    hasError () {
      // TODO: Notarized doc required?  If fileNotarized.documents_count <= 0 hasError = true
      /*
      let isError = true

      if (this.valid_claim_holder() && this.valid_notice()) {
        isError = !this.formIsValid
      }
       */

      return !this.formIsValid || !this.abatementDistributions.affidavit.signature
    },

    getPath () {
      return `user/${this.users.guid}/notarized`
    },

    async uploadFile (uploadedFile) {
      const docPath = this.getPath()

      if (docPath.length > 0) {
        await Storage.put(`${docPath}/${uploadedFile.name}`, uploadedFile)
          .then((result) => {
            console.info(`File '${uploadedFile.name}' uploaded successfully`)
            this.fileNotarized.documents_count++
          })
          .catch((err) => {
            // TODO: Gracefully display errors to the user
            console.error(err)
            // this.$store.dispatch('error/setError', { name: 'Uploading Document', details: err })
          })
      }
    },

    uploadNotarized (uploadedFiles) {
      if (uploadedFiles) {
        this.uploadFile(uploadedFiles)
      }
    }
  }
}
</script>

<style scoped>

</style>
