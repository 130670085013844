<template>
  <v-sheet id="contact">
    <v-container class="py-12">
      <v-row id="content_row">
        <v-col cols="2"></v-col>
        <v-col cols="8" sm="12" md="8" lg="8">
          <div class="text-h6 text-lg-h5 my-3" style="text-align: center">
            Need to get in touch with us? Find the department email that you would like to contact below or give us a call.
          </div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row id="contact_info_row">
        <v-col cols="2"></v-col>
        <v-col cols="8" sm="12" md="8" lg="8">
          <div class="font-weight-bold text-body-1" style="text-align: center">
            Claims Administrator<br>
            {{ config.product.name }}, {{ config.product.mailing_address_1 }}<br>
            {{ config.product.mailing_address_2 }}<br>
            {{ config.product.mailing_city }}, {{ config.product.mailing_state_cd }} {{ config.product.mailing_zip }}
          </div>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-row id="email_info_row">
        <v-col cols="2"></v-col>
        <v-col
          cols="8"
          sm="12"
          md="8"
          lg="8"
          align="center"
        >
          <a href="mailto: TAC@IERPtrust.com?subject=Contact IERP TAC" class="text-decoration-none" target="_blank">tac@ierptrust.com</a><br>
          <a :href="`mailto:${config.product.email_trustee}?subject=Contact IERP Trustee`" class="text-decoration-none" target="_blank">{{ config.product.email_trustee }}</a><br>
          <a :href="`mailto:${config.product.email_claims}?subject=Contact IERP Claims`" class="text-decoration-none" target="_blank">{{ config.product.email_claims }}</a><br>
          <a href="mailto: Info@IERPtrust.com?subject=Contact IERP Info" class="text-decoration-none" target="_blank">info@ierptrust.com</a>
        </v-col>
        <v-col cols="2"></v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'

export default {
  data: () => ({
    config
  })
}
</script>
