import { DataStore } from '@aws-amplify/datastore'
import { AbatementDistribution } from '@/models'

export default {
  init: ({ commit }) => {
    commit('init')
  },

  incrementDocCount: ({ commit }, payload) => {
    commit('incrementDocCount', payload)
  },

  // https://docs.amplify.aws/lib/datastore/data-access/q/platform/js/#querying-for-a-single-item
  create: async  ({ dispatch, rootState, state }) => {
    const thisAction = `Create ${state.displayAsSingular}`

    try {
      // If creating a new Abatement Dist row, then default claim_holder to individual data
      const new_claim_holder = { ...state.empty_contact }
      const full_name = `${rootState.individual.data.first_name} ${rootState.individual.data.last_name}`

      new_claim_holder.name = full_name.toString().trim()
      new_claim_holder.address1 = rootState.individual.data.mailing_address_1
      new_claim_holder.address2 = rootState.individual.data.mailing_address_2
      new_claim_holder.city = rootState.individual.data.mailing_city
      new_claim_holder.state = rootState.individual.data.mailing_state_cd
      new_claim_holder.zip = rootState.individual.data.mailing_zip
      new_claim_holder.phone = rootState.individual.data.phone
      new_claim_holder.email = rootState.individual.data.email

      await DataStore.save(
        new AbatementDistribution({
          'guid': rootState.user.users.guid,
          'claim_holder': new_claim_holder,
          'notice': state.updates.notice,
          'completer': state.updates.completer,
          'current_service_area': state.updates.current_service_area,
          'used_in_service_area': state.updates.used_in_service_area,
          'affidavit': state.updates.affidavit,
          'amounts': state.updates.amounts
        })
      )

      console.info(`${state.displayAsSingular} Created - ${new_claim_holder.name}, variant: success`)

      // If Abatement Dist needs to be created, then we should update the guid hook in IndividualVoter
      // so that we know where this users data is in the DataStore and files are in S3
      dispatch('individual/update', null, { root: true })
      dispatch('retrieve')
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
    }
  },

  retrieve: async ({ commit, dispatch, rootState, state }) => {
    const thisAction = `Retrieve ${state.displayAsSingular}`
    let abatementDist

    try {
      // TODO: Why is the Datastore.query not filtering?
      abatementDist = await DataStore.query(AbatementDistribution, (data) => {
        data.guid('eq', rootState.user.users.guid)
      })

      commit('push2Data', { data: abatementDist, guid: rootState.user.users.guid })

      if (rootState.individual.data.guid === null || rootState.individual.data.guid === '') {
        dispatch('individual/update', null, { root: true })
      }
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
    }
  },

  update: async ({ dispatch, state }) => {
    const thisAction = `Update ${state.displayAsSingular}`

    try {
      const original = await DataStore.query(AbatementDistribution, state.data.id)

      await DataStore.save(
        AbatementDistribution.copyOf(original, (updated) => {
          updated.claim_holder = state.updates.claim_holder
          updated.notice = state.updates.notice
          updated.completer = state.updates.completer
          updated.current_service_area = state.updates.current_service_area
          updated.used_in_service_area = ( state.updates.used_in_service_area === null ? null : !!state.updates.used_in_service_area )
          updated.affidavit = state.updates.affidavit
          updated.amounts = state.updates.amounts
        })
      )

      dispatch('retrieve')
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
    }
  }
}
