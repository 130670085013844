<template>
  <v-app>
    <login-dialog
      ref="loginDialog"
    />

    <v-card
      class="d-flex justify-center mb-3"
    >
      <v-container
        class="pa-0 ma-0"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="12"
            class="ma-1"
          >
            <individuals-table
              v-if="userAuthState === 'signedin'"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <logged-in-footer
      v-if="userAuthState === 'signedin'"
      ref="loggedInFooter"
    />
  </v-app>
</template>

<script>
import config from '@/configs'
import LoginDialog from '@/components/ui/ierp/components/controls/LoginDialog'
import IndividualsTable from '@/components/ui/ierp/components/tables/IndividualsTable'
import LoggedInFooter from '@/components/ui/ierp/components/controls/LoggedInFooter'

export default {
  name: 'AdminView',

  components: {
    LoginDialog,
    IndividualsTable,
    LoggedInFooter
  },

  data: () => ({
    config
  }),

  computed: {
    userAuthState () {
      let authState = ''

      if (this.$store.state.user.users !== null) {
        if ({}.hasOwnProperty.call(this.$store.state.user.users, 'authState')) {
          authState = this.$store.state.user.users.authState
        }
      }

      return authState
    }
  },

  created () {
    // TODO: Edit from Admin View and Add pre-generated emails
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'user/successfulLogin':
        this.initialize()
        break
      default:
        break
      }
    })
  },

  mounted() {
    if (this.userAuthState === 'signedin' && !this.$store.state.user.users.isAdmin) {
      this.redirectNonAdmin()
    }
  },

  beforeDestroy () {
    this.unsubscribe()
  },

  methods: {
    redirectNonAdmin () {
      this.$router.push({ name: 'home' })
    },

    initialize () {
      if (!this.$store.state.user.users.isAdmin) {
        this.redirectNonAdmin()
      }
    }
  }
}
</script>
