import moment from 'moment'

export default {
  init: (state) => {
    state.data = {}
  },

  setIsBusy: (state, isBusy) => {
    state.isBusy = isBusy
  },

  push2All: (state, dataFromDB) => {
    state.all = [...dataFromDB]
    state.isBusy = false
  },

  push2Data: (state, payload) => {
    // Ensure we only have the logged in individual
    for (const currentData of payload.data) {
      if (payload.email.toString().trim() === currentData.email.toString().trim()) {
        state.data = { ...currentData }
      }
    }

    state.lastReload.datetime = moment()
    state.isBusy = false
  }
}
