import moment from 'moment'

function initialize (state) {
  state.data = {}

  state.empty.claim_holder = { ...state.empty_contact }
  state.empty.notice = { ...state.empty_contact }
  state.empty.completer = { ...state.empty_contact }
  state.empty.affidavit = { ...state.empty_contact }

  state.updates = { ...state.empty }
}

export default {
  init: (state) => {
    initialize(state)
  },

  push2Data: (state, payload) => {
    initialize(state)

    let dispatchCreate = true

    // Ensure we only have the logged in individual
    for (const currentData of payload.data) {
      if (payload.guid.toString().trim() === currentData.guid.toString().trim()) {
        dispatchCreate = false

        state.data = { ...currentData }
        state.updates.claim_holder = { ...currentData.claim_holder }
        state.updates.notice = { ...currentData.notice }
        state.updates.completer = { ...currentData.completer }
        state.updates.current_service_area = currentData.current_service_area
        state.updates.used_in_service_area = currentData.used_in_service_area
        // state.updates.plan = { ...currentData.plan }
        state.updates.affidavit = { ...currentData.affidavit }

        state.updates.amounts = []

        for (const currentItem of currentData.amounts) {
          state.updates.amounts.push({ ...currentItem })
        }
      }
    }

    payload.dispatchCreate = dispatchCreate

    state.lastReload.datetime = moment()
  },

  incrementDocCount: (state, payload) => {
    for (const currentItem of state.updates.amounts) {
      if (currentItem.year === payload.year) {
        currentItem.documents_count++
      }
    }
  }
}
