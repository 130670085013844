<template>
  <v-form v-model="formIsValid">
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <div id="attachment-C" class="font-italic pb-1">
          Attachment C
        </div>
        <h3 class="font-italic text-center pb-2">
          Independent Emergency Room Physicians Trust Authorized Recipient’s Instruction and Agreement Form
          <sup>3</sup>
        </h3>
        <div class="text-left pb-1">
          I hereby acknowledge that I have received an {{ displayAsSingular }} from the IERP Trust.
          Pursuant to the IERP Trust and IERP Trust Procedures, I agree and hereby certify:
        </div>
        <div class="text-left pb-1">
          <ol
            type="1"
            class="ma-0 pa-0 pl-2"
          >
            <li class="pt-1">
              To only use the abatement monies per the representations in provided by
              me in the Independent Emergency Room Physicians Trust {{ displayAsSingular }} Form.
            </li>
            <li class="pt-1">
              I agree I am solely responsible for all applicable local, state, and federal taxes and
              any obligations to the IRS related to receipt of any {{ displayAsSingular }}.
            </li>
            <li class="pt-1">
              I agree pursuant to the IERP Trust to report to the Trustee Quarterly.
              Reports are due no later than the 15th day of April, July October and January of each year
              subsequent to receipt of the {{ displayAsSingular }} unless the Trustee informs me of
              another schedule at the time of receipt of the {{ displayAsSingular }}.
              Reports will be sent to
              <a :href="`mailto:${config.product.email_trustee}?subject=[name] Report to the Trustee for the [qtr year].`" class="text-decoration-none" target="_blank">{{ config.product.email_trustee }}</a>.
              The subject line of the email will be:
              [insert name of the IER Physicians Trust Authorized Recipient/Report] to Trustee for the
              [insert qtr and year]. Example of subject line:
              Dr. Jim Smith/Report to the Trustee for the 4th Qtr 2022.
            </li>
            <li class="pt-1">
              I agree that at any time, the trustee can request additional reports to
              the Trustee Quarterly Reports in #3 above, and unless otherwise specified by the Trustee,
              ALL reports will explain in detail how the {{ displayAsSingular }} has been utilized up to
              the time of the report and will show compliance with the IER Physician Authorized Abatement Purposes.
              The information shall include but is not limited to:
              <div class="pt-1 pl-2">
                1) Summary of the efforts to date related to the Abatement purposes identified in the {{ displayAsSingular }} Form,
              </div>
              <div class="pl-2">
                2) All materials developed and/or utilized to establish one or more of the Abatement purposes identified in the {{ displayAsSingular }} Form,
              </div>
              <div class="pl-2">
                3) Receipts of all expenditures,
              </div>
              <div class="pl-2">
                4) A description of the next phases of the project, if any, and the timeline for completion.
              </div>
              <div class="pt-1 text-decoration-underline">
                Reports will be due 90 days after the request by the Trustee and Quarterly Reports as specified in #3 above.
              </div>
            </li>
            <li class="pt-1">
              I agree that if I fail to submit any report as required in #3 and #4 above,
              I will repay the entire Distribution amount and will be personally responsible for
              any Attorney Fees and costs incurred by the Trust to enforce this Agreement.
              The amount of such distribution shall irrevocably revert to the IER Physician Trust
              and my claim with respect to such distribution shall be forever barred from
              assertion against the IER Physician Trust or its property.
            </li>
          </ol>
        </div>
      </v-col>
    </v-row>
    <!-- Attachment C - Fine Print #3 -->
    <v-divider class="mt-1" style="page-break-after: avoid;" />
    <v-row
      no-gutters
      style="page-break-after: always;"
      class="d-print-block"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1 fine-print"
      >
        <sup>3</sup> This Agreement will be sent to the IER Physicians Trust
        Authorized Recipient after determination of {{ displayAsSingular }} amount and
        must be completed prior to distribution of such the {{ displayAsSingular }}
      </v-col>
    </v-row>
    <!-- New Page -->
    <v-divider class="mt-3 mb-3 d-print-none" />
    <!-- Attachment C Signature -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <ol
          type="1"
          start="6"
          class="ma-0 pa-0 pl-2"
        >
          <li>
            I agree the Trustee has the right to Audit me at any time to determine whether the
            IER Physician Authorized Recipient’s expenditures for IER Physician Authorized Abatement Purposes
            have met the requirements set forth in the IER Physician Trust Documents.
          </li>
          <li class="pt-1">
            I agree all payments made for one or more of said IER Physician Authorized Abatement Purposes
            shall be subject to audit by the Trustee of the IER Physician Trust and shall be repaid with a
            ten percent (10%) penalty added for any funds found by audit to fail to comply with requirements
            as set forth herein and pay all attorney fees and costs associated with
            any action required to enforce this provision.
          </li>
          <li class="pt-1">
            I agree the Trustee is authorized to collect tax information,
            which may include applicable IRS Form W-9, from me,
            the Holder of IER Physician Channeled Claims (including tax identification numbers)
            as reasonably requested by the Trustee, readily available to me.
            The Trustee may refuse to make some or all of a distribution to me if I fail to
            furnish such information in a timely fashion,
            and until such information is delivered can treat my Claim, as disputed.
            If I provide the requested information,
            I understand the Trustee shall make such distribution to which I am,
            without additional interest occasioned by the delay in providing tax information.
            Notwithstanding the foregoing, if I fail to furnish any tax information reasonably requested by
            the Trustee before the date that is six months after the request is made
            (subject to extension in the discretion of the Trustee if I demonstrate to the reasonable
            satisfaction of the Trustee that my failure to provide such tax information is due to one or
            more taxing authorities’ failure to furnish information necessary to
            respond to the Trustee’s reasonable request), the amount of such distribution shall
            irrevocably revert to the IER Physician Trust and my claim with respect to such distribution
            shall be forever barred from assertion against the IER Physician Trust or its property.
          </li>
        </ol>
        <div class="pt-4">
          By my signature below I hereby agree and certify to the
          conditions for receipt of an {{ displayAsSingular }}.
          I understand the disbursement of such Distribution will occur
          within 14 days of the Trustee’s receipt of this agreement.
        </div>
        <div class="pt-2">
          <signature-line
            :value="abatementDistributions.completer.signature"
            label="Claimholder's Signature:"
            @change="completerSignature_onChange"
          />
        </div>
        <!-- For now this Person Completing this Form section is print only -->
        <div class="print-only">
          <div class="pt-4 d-inline-block">
            Person Completing this Form
            <div class="d-print-none d-inline-block">
              <!-- Note: Had to use :value here because v-model was interrupting the SameAs_onChange code -->
              <v-checkbox
                :value="abatementDistributions.completer.is_same_as"
                label="Same as Claimholder"
                class="pa-0 ma-0 pl-2 d-inline-block"
                dense
                @change="completer_SameAs_onChange"
              />
            </div>
          </div>
          <printable-text-field
            ref="completingName"
            v-model="abatementDistributions.completer.name"
            label="Name"
            class="pt-1"
            cols="8"
            :required="true"
            @blur="textField_onBlur($event, (originalData.completer ? originalData.completer.name : null))"
          />
          <address-block
            ref="completingAddress"
            v-model="abatementDistributions.completer"
            :required="true"
            @blur="address_onBlur(abatementDistributions.completer, originalData.completer)"
          />
          <div class="pt-4">
            Contact Information:
          </div>
          <printable-text-field
            ref="completingPhone"
            v-model="abatementDistributions.completer.phone"
            label="Phone"
            cols="4"
            maxlength="16"
            class="pt-1"
            :required="true"
            :rules="[config.rules.validPhone]"
            @blur="textField_onBlur($event, (originalData.completer ? originalData.completer.phone : null))"
          />
          <printable-text-field
            ref="completingEmail"
            v-model="abatementDistributions.completer.email"
            label="Email"
            cols="10"
            maxlength="256"
            :required="true"
            :rules="[config.rules.validEmail]"
            @blur="textField_onBlur($event, (originalData.completer ? originalData.completer.email : null))"
          />
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import config from '@/configs'
import AddressBlock from '@/components/ui/ierp/components/controls/AddressBlock'
import PrintableTextField from '@/components/ui/ierp/components/controls/PrintableTextField'
import SignatureLine from '@/components/ui/ierp/components/controls/SignatureLine'

export default {
  name: 'AttachmentC',

  components: {
    AddressBlock,
    PrintableTextField,
    SignatureLine
  },

  data: () => ({
    config,
    formIsValid: false
  }),

  computed: {
    empty_contact () {
      return this.$store.state.abatementDistribution.empty_contact
    },
    abatementDistributions () {
      return this.$store.state.abatementDistribution.updates
    },
    originalData () {
      return this.$store.state.abatementDistribution.data
    },
    displayAsSingular () {
      return this.$store.state.abatementDistribution.displayAsSingular
    }
  },

  created() {
    // TODO: Remove the line below once we determine if the completer section is needed online or not
    this.abatementDistributions.completer = { ...this.empty_contact }
  },

  methods: {
    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)
        this.$store.dispatch('abatementDistribution/update')
      })
    },

    hasError () {
      return !this.abatementDistributions.completer.signature
    },

    textField_onBlur (value, originalData) {
      if (value !== null && originalData !== null) {
        if (value.toString().trim() !== originalData.toString().trim()) {
          this.onChange(value)
        }
      }
    },

    address_onBlur (updatedData, originalData) {
      if (updatedData.address1 !== originalData.address1 ||
        updatedData.address2 !== originalData.address2 ||
        updatedData.city !== originalData.city ||
        updatedData.state !== originalData.state ||
        updatedData.zip !== originalData.zip) {
        this.onChange(updatedData)
      }
    },

    completer_SameAs_onChange (isSame) {
      if (isSame) {
        this.abatementDistributions.completer = { ...this.abatementDistributions.claim_holder }
      } else {
        this.abatementDistributions.completer = { ...this.empty_contact }
      }

      this.abatementDistributions.completer.is_same_as = isSame

      this.onChange(this.abatementDistributions.completer)
    },

    completerSignature_onChange (evt) {
      this.abatementDistributions.completer.signature = evt
      this.onChange(evt)
    }
  }
}
</script>

<style scoped>

</style>
