<template>
  <v-sheet>
    <v-container class="py-12">
      <v-expansion-panels
        multiple
        class="pt-3"
      >
        <!-- ===================================================================================================== -->
        <!-- Question 1                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">1. Who is Mallinckrodt, what is the case about, and why is there a Trust?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Mallinckrodt <b>Pharmaceuticals</b> is an American-Irish domiciled manufacturer of specialty
            pharmaceuticals, generic drugs including generic Opioids and imaging agents. They are one of the
            largest manufacturers of hydrocodone and oxycodone.
            <br><br>
            The company has been implicated as a major contributor to the prescription opioid scandal surrounding the
            inappropriate prescription of oxycodone and hydrocodone in the United States.
            <br><br>
            In 2017 Mallinckrodt paid a $35 million fine to settle DEA complaints that it did not adequately
            address suspicious opioid orders, acknowledging “certain aspects of Mallinckrodt’s system to monitor
            and detect suspicious orders did not meet" DEA standards.
            <br><br>
            Using the DEA evidence and admissions by Mallinckrodt that its system was deficient, a number of
            lawsuits were filed against Mallinckrodt alleging it was negligent and was instrumental in the
            opioid crises in relation to the inappropriate prescribing of opioids.
            <br><br>
            In 2020 faced with the multiple opioid related lawsuits, the company filed for Chapter 11 Bankruptcy
            protection. The company's Court approved reorganization plan includes a $1.7 billion settlement to
            resolve thousands of lawsuits accusing it of deceptively marketing its opioids.
            <br><br>
            On March 2, 2022, the Bankruptcy Court entered an order approving the Joint Plan of Reorganization
            and the Disclosure Statement (<i>Content can be viewed at
            <router-link to="DocumentsMaster" target="_blank">{{ config.product.url }}</router-link>
            in the documents page</i>) which included procedures for establishing Trusts,
            including this Trust, to distribute funds to eligible claimants for use
            in abatement of opioid misuse and abuse.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 2                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">2. What is the {{ config.product.name }}?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            It is clear that a number of people and entities were damaged as a result of the actions of a
            number of companies, including Mallinckrodt, by their actions in facilitating the opioid crisis.
            Emergency Room Physicians are often involved in all aspects of care of patients suffering from
            Opioid Use Disorder (“OUD”). Covered by this Trust are the Emergency Room Physicians that are not
            employed by Hospitals (please see No. 7 below). These Independent Emergency Room Physicians (IERPs)
            were injured as well as other medical care treatment providers and facilities. The damages can be
            counted in lost time, billings etc. This damage was recognized by the US Trustee and it was
            ascertained that IERP’s, could have a substantial impact in helping alleviate the opioid misuse
            crises by utilizing effective abatement measures. Thus, the {{ config.product.name }} was created to provide
            distributions for IERP’s to use for abatement measures.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 3                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">3. How can IERP’s help?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            The court has recognized the vital role IERP’s have in diagnosing and treating patients with OUD
            and agreed that the abatement measures authorized by the Trust can have a significant impact as
            part of addressing this crisis.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 4                                                                                           -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">4. How are the funds involved going to be spent?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Under the approved Joint Plan of Reorganization and the Disclosure Statement (and all the Trusts
            procedures included in that Plan) all money (after trust expenses etc. ) distributed by any Trust
            must be used for Abatement of the opioid crises. Each Trust has its own Authorized Abatement uses.
            A significant amount of the available funds were distributed to the States. Each state can decide
            how best to use the funds. We feel it is important to recognize that individuals and family
            members had Trusts established on their behalf which can be located in the MDT II which is linked
            to in the Document section of this website.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 5                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">5. Can I sue MLNK directly myself?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            No. In summary, when an entity files in Bankruptcy, any claims that were filed against it for
            damages are placed into the Bankruptcy and are under the Bankruptcy Courts jurisdiction. When the
            Plan related to a company is confirmed those claims are “paid” for or placed in a process to
            ascertain if they will be paid (in this matter one of the Trusts). In exchange for the monies
            provided by the Company from its assets to fund the Trusts, the Company is given a Release from
            prior and future liability related to claims that were filed or that could have been filed against
            it. All claims filed prior to Bankruptcy or during are in the bankruptcy and covered by that
            process. So if you could have alleged a claim against Mallinckrodt those claims have now been
            released.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 6                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">6. What is a channeled claim?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            A claim is any claim by a person/entity against Mallinckrodt that asserts they were damaged by
            Mallinckrodt because of its manufacture and sale of generic opioids. All the claims made prior to
            and during the Bankruptcy case were consolidated and placed into the Bankruptcy case. A “claim”
            is included in the Bankruptcy and was “channeled” into one of the Trusts created by the Confirmation
            Plan.That means all claims must go through and will be addressed by the relevant Trust the claim
            was “channeled” into. In this matter, any Independent Emergency Room Physician that either filed a
            claim or now asserts they were damaged by Mallinckrodt, is covered by the {{ config.product.name }} and must use
            the IERP TDP process.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 7                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">7. Who is an Independent Emergency Room Physician and what years are covered?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Independent Emergency Room Physician is defined as an Emergency Room Physician that was
            a non-hospital employee that worked in an emergency room as defined by EMTALA anytime during the
            years 2001-2019.<br>
            <br>
            If during the years identified above, you were an Emergency Room Physician employed by a Hospital,
            your Hospital employer may have a Hospital Trust Channeled claim under the Hospital Trust in the
            MDT II.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 8                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">8. If you were an IERP during all or some of the relevant time period, how do you start the process?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            On the website at
            <router-link to="DocumentsMaster" target="_blank">{{ config.product.url }}</router-link>,
            print off the Step 1 form.  Complete the form and mail it to:<br>
            <br>
            Claims Administrator<br>
            {{ config.product.name }}, {{ config.product.mailing_address_1 }}<br>
            {{ config.product.mailing_address_2 }}<br>
            {{ config.product.mailing_city }}, {{ config.product.mailing_state_cd }} {{ config.product.mailing_zip }}
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 9                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">9. Who decides if I am eligible for a trust distribution?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            The Trustee will determine if you are eligible for a distribution by using the procedures outlined in
            the Trust Distributions Procedures (TDP).
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 10                                                                                          -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">10. Is any Independent Emergency Room Doctor eligible to receive trust funds?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Yes if the IERP follows the process outlined in the TDP, meets all the qualifications, agrees to use the
            Distribution for abatement purposes and is approved by the Trustee per the procedures in the TDP.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 11                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">11. I don’t have a scanner for documents, or I have too many documents to scan.  How can I get the Claims Administrator the documentation that is required?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            If you cannot electronically upload required information, please notify the Claims Administrator via
            email of the issue. The Claims Administrator will provide direction on how you can submit required
            documentation.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 12                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">12. After Step 1 if I am notified I am a channeled claims holder what is the next step to see if  I am eligible for a disbursement?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            If you completed Step 1-the <i>Independent Emergency Room Physicians Trust Notice of Possible Eligibility for
            Abatement Distribution Award</i> , the Trustee will notify you if you are a channeled claim holder. In that
            notification the Trustee will provide you a temporary password to sign into Website where you will now
            be able to electronically complete the <i>Independent Emergency Room Physicians Abatement Distribution Form</i>.
            That form determines the amount of any distribution you will be awarded.
            <br><br>
            The <i>Independent Emergency Room Physicians Abatement Distribution Form</i> also includes the Authorized
            Abatement Procedures and a copy of the Agreement that the Independent Emergency Room Physician will be
            required to enter into before receiving any Distribution.  You should read that Agreement in full before
            completing the <i>Independent Emergency Room Trust Distribution Form</i> to determine if you can agree to and
            will abide by the terms.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 13                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">13. How long will it take to get the money if I am eligible?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Each form has the deadlines to file and for Trustee responses.  On average the entire process can be
            completed in approximately eight (8) months from the date of receipt of the NOTICE OF POSSIBLE ELIGIBILITY
            FOR ABATEMENT DISTRIBUTION AWARD- STEP ONE
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 14                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">14. Do I need to have an attorney to file for a distribution?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            No. The process is designed for you to be able to complete without an attorney.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 15                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">15. What can a distribution be used for and where must the distribution be used?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            As ordered by the Bankruptcy Court, any distribution MUST only be used for Authorized abatement
            purposes. The list of Authorized abatement purposes can be found under documents
            <a href="https://ierp-documents.s3.amazonaws.com/Authorized+Abatement+Purposes.pdf">here.</a>
            <br><br>
            Additionally, The Trust requires that distributions be made only in the Authorized Recipients
            respective Service Area.
            <br><br>
            You can use the money to establish a new program or to fund an existing eligible program.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 16                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">16. Why do you have to agree to be audited to ensure you are using the money for abatement?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Under the MDT II Trust, the {{ config.product.name }} must ensure all monies (less attorney fees and authorized
            expenses etc.) including all Distributions, are used for Authorized Abatement purposes.  To ensure the
            Trust is complying, and all Authorized Recipients are complying with their representation that all
            Distributions be used for Abatement, the Trustee has the right and the obligation to audit how the
            distributions are being used, report that information to the MDT II Trust,  and to abide by the IERP
            Trust obligations thereunder.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 17                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">17. What information do I have to provide the Trustee after I receive the Distribution?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            The Trustee has the right to require any information needed to ensure any Distribution is being used for
            Authorized Abatement Purposes.  Additionally, as the Trustee must provide regular reports on how
            Distributions are being utilized, the Trustee will require quarterly and annual reports from the
            Authorized Recipient.  The Trustee has the right to require additional information at any time.
            <br><br>
            Reports from the IERP on use of the Distribution are due no later than the 15th day of April, July,
            October, and January of each year subsequent to receipt of the Abatement Distribution unless the Trustee
            determines a different date.
            <br><br>
            Reports will explain in detail how the Abatement Distribution has been utilized up to the time of the
            report and describe in detail how expenditures are in compliance with the IER Physician Authorized
            Abatement Purposes. The information shall include but is not limited to:
            <br><br>
            <ul>
              <li>Summary of the efforts to date related to the Abatement purposes identified in the Abatement
                Distribution Form</li>
              <li>All materials developed and/or utilized to establish one or more of the Abatement purposes identified in the Abatement Distribution Form</li>
              <li>Receipts of all expenditures</li>
              <li>A description of the next phases of the project, if any, and the timeline for completion</li>
              <li>financial statements including Profit and loss, Balance Sheet</li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 18                                                                                          -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">18. What if you don’t use the Distribution or use it for purposes other than abatement.</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            If an Authorized Recipient does not use the distribution the Recipient shall be disqualified from receipt
            of additional distributions and disqualified.  Additionally pursuant to the Trust the recipient can face
            legal action by the Trustee to seek recovery of any distribution not used for Abatement purposes. Any
            recovery will be for the Distribution amount not used for Abatement purposes, plus a ten percent (10%)
            penalty added and all attorney fees and costs associated with any action.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 19                                                                                           -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">19. What if I miss one of the deadlines?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Don’t worry.  The TDP permits the Trustee to grant extensions of time in some circumstances.  If you miss
            a deadline or know that you are going to miss a deadline, email the Claims Administrator at
            <a
              :href="`mailto:${config.product.email_claims}?subject=${config.product.email_subject_deadline}&body=${config.product.email_body_deadline}`"
              target="_blank"
            >{{ config.product.email_claims }}</a>
            , as soon as possible.  Explain what deadline was missed and provide an explanation. The Claims Administrator will respond.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 20                                                                                         -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">20. Who do I contact for questions?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            For questions related to the claims process please email
            <a
              :href="`mailto:${config.product.email_claims}`"
              target="_blank"
            >{{ config.product.email_claims }}</a>
            <br>
            For questions related to the trust email
            <a
              :href="`mailto:${config.product.email_trustee}?subject=${email_subject_questions}&body=${email_body_questions}`"
              target="_blank"
            >{{ config.product.email_trustee }}</a>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 21                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">21. Can the Trust help me with refining my abatement plan?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Yes.  After you complete Step 1 and are determined you are a Channeled claims holder and will be applying
            for a distribution, if you want help, email the Trustee and ask for assistance. We don’t want you to have
            to reinvent the wheel.  You know best what the need is in your Service Area but the Trustee can assist in
            providing you with insights on practical steps for implementation. The Trustee can also identify other
            programs in your area that are providing authorized Abatement uses that you may want to partner with.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 22                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">22. Can I combine my distribution with others in order to have a larger impact?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Yes.  As long as you identified combining the distribution with an organization and/or entity that provides
            authorized abatement services/uses, and this was in the plan you submitted to the Trustee and which the
            Trustee approved.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 23                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">23. What are the tax implications?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            Pursuant to the {{ config.product.name }} document and MDT II anyone that receives a distribution is responsible
            for any and all tax implications. We recommend you consult a tax professional.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <!-- ===================================================================================================== -->
        <!-- Question 24                                                                                            -->
        <!-- ===================================================================================================== -->
        <v-expansion-panel>
          <v-expansion-panel-header class="text-h5">24. What is the deadline to complete entire application Step 1 and Step 2 forms?</v-expansion-panel-header>
          <v-expansion-panel-content class="text-h6 secondary--text">
            <b style="color: red">The entire process, including Step 1 and Step 2 must be completed by the
            Applicant/Holder of a Channeled Claim, no later than July 31, 2023 to be considered for an Abatement
            Distribution.</b> Please take the deadlines in the Step 1 and Step 2 forms for Claims Administrator
            approval and processing into consideration when completing both Steps.
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-sheet>
</template>

<script>
import config from '@/configs'

export default {
  data: () => ({
    config,
    email_subject_questions: 'IERP Questions or Missed Deadline',
    email_body_questions: 'Please consider the questions or missed deadline and reason explained below...'
  })
}
</script>
