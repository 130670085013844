import { DataStore } from '@aws-amplify/datastore'

export default {
  init: async ({ dispatch }) => {
    await DataStore.start()

    dispatch('abatementDistribution/init', null, { root: true })
    dispatch('individual/init', null, { root: true })
    dispatch('user/init', null, { root: true })
  },

  // http://maps.googleapis.com/maps/api/geocode/json?address=29212&sensor=true
  retrieveCityState: ({ commit, dispatch, rootState, state }, payload) => {
    const thisAction = 'ziptasticapi'

    const actionEndPoint = `https://${thisAction}.com`
    const url = `${actionEndPoint}/${payload.zip}`

    const options = {
      method: 'get'
    }

    fetch(url, options)
      .then((response) => {
        const statusMessage = `${response.status}: "${response.statusText}"`

        if (!response.ok) {
          throw Error(statusMessage)
        }

        return response.json()
      })
      .then((jsonResponse) => {
        if ({}.hasOwnProperty.call(jsonResponse, 'city')) {
          payload.city = jsonResponse.city
        }

        if ({}.hasOwnProperty.call(jsonResponse, 'state')) {
          payload.state = jsonResponse.state
        }

        commit('successfulZipLookup')
      })
      .catch((error) => {
        console.error(`${thisAction} failed with url: ${url}`)
        // TODO: Create error module
        // dispatch('error/setError', { name: thisAction, details: error }, { root: true })
      })
  }
}
