export const menu = [{
  title: 'Home',
  link: '/HomeMaster'
}, {
  title: 'Documents',
  link: '/DocumentsMaster'
}, {
  title: 'FAQ',
  link: '/FaqMaster'
}, {
  title: 'Contact',
  link: '/ContactMaster'
}]

export default {
  menu
}
