<template>
  <Footer />
</template>

<script>
import Footer from '@/components/ui/ierp/pages/Footer.vue'

export default {
  components: {
    Footer
  }
}
</script>
