<template>
  <v-app>
    <v-alert
      v-if="alert.show"
      shaped
      prominent
      :icon="alert.icon"
      :type="alert.variant"
    >
      <h2>
        {{ alert.title }}
      </h2>
      <v-divider />
      <div
        v-for="(thisMessage, index) in alert.messages"
        :key="index"
        class="pt-1"
      >
        {{ thisMessage }}
      </div>
    </v-alert>

    <v-snackbar
      v-model="toast2Display.display"
      :timeout="toast2Display.timeout"
      :color="toast2Display.variant"
      v-bind="{
        [toast2Display.parsedDirection[0]]: true,
        [toast2Display.parsedDirection[1]]: true
      }"
      :multi-line="true"
      class="d-print-none"
    >
      <div>
        <div class="pb-2">
          <h3>{{ toast2Display.title }}</h3>
        </div>
        <v-divider />
        <div
          v-for="(thisMessage, index) in toast2Display.messages"
          :key="index"
          class="pt-1"
        >
          {{ thisMessage }}
        </div>
      </div>

      <template v-slot:action="{ }">
        <v-icon
          aria-label="Close"
          @click="toast2Display.display = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>

    <router-view />
  </v-app>
</template>

<style>
.print-only {
  display: none;
}
@media print
{
  .print-only {
    display: block;
  }
  h2 {
    height: auto;
    font-size: 18pt;
  }
  h3 {
    height: auto;
    font-size: 14pt;
  }
  .d-print-block {
    height: auto;
    font-size: 12px;
  }
  .fine-print {
    font-size: 8px;
  }
}
</style>

<script>
import config from './configs'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },

  metaInfo: {
    title: 'IERP',
    titleTemplate: '%s Trust',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },

  data () {
    return {
      alert: {
        icon: 'mdi-shield-lock-outline',
        show: false,
        messages: '',
        title: '',
        variant: 'error'
      },
      initializedToast: {
        display: false,
        title: '',
        messages: [],
        text: '',
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000
      },
      toast2Display: {
        display: false,
        messages: [],
        variant: 'success',
        parsedDirection: ['top', 'center'],
        timeout: 4000
      }
    }
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'user/successfulLogin':
        this.alert.show = false

        break
      case 'user/showAlert':
        this.alert.title = mutation.payload.title
        this.alert.messages = mutation.payload.messages
        this.alert.variant = mutation.payload.variant
        this.alert.show = true

        break
      case 'user/showToast':
        this.displayToast(mutation.payload)

        break
      }
    })
  },

  beforeDestroy () {
    this.unsubscribe()
  },

  methods: {
    displayToast (payload) {
      this.toast2Display = { ...this.initializedToast }

      this.toast2Display.title = payload.title
      this.toast2Display.messages = payload.messages

      if (payload.variant) {
        this.toast2Display.variant = payload.variant
      }

      if (payload.target) {
        this.toast2Display.parsedDirection = payload.target.split(' ')
      }

      if (payload.timeout) {
        this.toast2Display.timeout = payload.timeout
      }

      this.toast2Display.display = true
    }
  }
}
</script>
