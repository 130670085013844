// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ClaimStatus = {
  "IN_PROCESS": "IN_PROCESS",
  "PENDING_APPROVAL": "PENDING_APPROVAL",
  "MORE_INFO": "MORE_INFO",
  "MISSED_DEADLINE": "MISSED_DEADLINE",
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED",
  "DENIED": "DENIED",
  "COMPLETE": "COMPLETE"
};

const { AbatementDistribution, GroupVoter, IndividualVoter } = initSchema(schema);

export {
  AbatementDistribution,
  GroupVoter,
  IndividualVoter,
  ClaimStatus
};