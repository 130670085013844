/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://b5zhb5byg5b27kfc75b5zsmfvu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7sjvrwcpvfbqnkzscw55mdzaqa",
    "aws_cognito_identity_pool_id": "us-east-1:96353210-f051-409f-94ec-e1cf04b0a046",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QYNkkT5uT",
    "aws_user_pools_web_client_id": "2f9rqqalbgf7ur99v8ht8t4ukr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ierp-user-uploads120906-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
