<template>
  <Toolbar />
</template>

<script>
import Toolbar from '@/components/ui/ierp/toolbar/Toolbar.vue'

export default {
  components: {
    Toolbar
  }
}
</script>
