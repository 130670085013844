<template>
  <!-- Padded space so footer doesn't obstruct the bottom of the form -->
  <div class="pt-8 d-print-none">
    <!-- Footer: d-print-none button row -->
    <v-footer
      class="ma-0 pa-0 d-print-none"
      fixed
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center"
      >
        <slot name="beforeSignOut" />

        <v-btn
          color="accent"
          min-width="150"
          class="mr-1 font-weight-medium text-body-1"
          @click="signOut"
        >
          Sign Out
        </v-btn>

        <slot name="afterSignOut" />
      </v-col>
    </v-footer>
  </div>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'LoggedInFooter',

  methods: {
    async signOut () {
      try {
        await Auth.signOut()
        await this.$store.dispatch('user/setAuth', { authState: 'signedout', authData: undefined })
      } catch (error) {
        console.error('error signing out: ', error)
      }
    }
  }
}
</script>

<style scoped>

</style>
