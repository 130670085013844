<template>
  <v-form v-model="formIsValid">
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center"
      >
        <h3>INDEPENDENT EMERGENCY ROOM PHYSICIANS TRUST</h3>
      </v-col>
    </v-row>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center pt-2"
      >
        <h3>{{ displayAsSingular.toString().toUpperCase() }} FORM</h3>
      </v-col>
    </v-row>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-3"
      >
        <h3>
          {{ config.product.name.toUpperCase() }} {{ displayAsSingular.toString().toUpperCase() }} FORM DEADLINE: 90 days from receipt of this form.
          <sup>1</sup>
        </h3>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-3"
      >
        Based upon the information provided and verified on the
        <b>NOTICE OF POSSIBLE ELIGIBILITY FOR {{ displayAsSingular.toString().toUpperCase() }}</b>
        form you submitted, you qualify as a
        <i>Holder of IER Physicians Channeled Claim</i>
        and may therefore apply to be,
        <i>an IER Physicians Trust Authorized Recipient</i>.
        <i>An IER Physicians Trust Authorized Recipient</i>
        is eligible to receive IER Physician Trust funds to be used for the
        purpose of abatement addressing one or more of the abatement purposes outlined in the
        <i>IER Physicians Trust Distribution Plan</i>.
        (Click on the link to view the document.)
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1"
      >
        The information provided in this <i>{{ displayAsSingular }} Form</i>
        will be used to determine the IER Physician’s Abatement distribution amount (if any)
        from the IER Physicians Trust (as defined in the Plan, the “the <u>Trust</u>”)
        for which you would be eligible.
        The formula used to compute award amounts will consider the number of years
        you experienced lost or reduced compensation when treating opioid misuse or
        OUD patients in the ER, the estimated number of OUD patients treated
        during those years (national average of 0.33 OUD patients per hour),
        and the payor break down (using national averages) of uninsured, Medicare,
        and Medicaid patients and approximate number of patients within each of those categories
        (based on national averages) to determine your approximate damages.
        That figure will then be a percent of the total damages for all
        independent ER physicians and will be used to determine the {{ displayAsSingular.toString().toLowerCase() }} amount
        based upon available funds in the Trust.
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1"
      >
        Please be advised that a person who files a fraudulent claim on behalf of a Holder of
        IER Physicians Channeled Claim may, at a minimum, be fined up to $500,000.00,
        imprisoned for up to 5 years, or both. 18 U.S.C. §§ 152,157.
        Holders of IERP Channeled Claims shall provide the information requested that is,
        to the best of their knowledge, current and valid as of the date this Form is complete and
        delivered to the IER Physicians Trustee via the website at
        <router-link to="../" target="_blank">{{ config.product.url }}</router-link>;
        email to
        <a
          :href="`mailto:${config.product.email_claims}?subject=${email_subject}&body=${email_body}`"
          target="_blank"
        >{{ config.product.email_claims }}</a>
        or US Mail to:
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1 font-weight-bold"
      >
        <div style="display: block;">Claims Administrator,</div>
        <div style="display: block;">{{ config.product.name }}, {{ config.product.mailing_address_1 }}</div>
        <div style="display: block;">{{ config.product.mailing_address_2 }}</div>
        <div style="display: block;">{{ config.product.mailing_city }}, {{ config.product.mailing_state_cd }} {{ config.product.mailing_zip }}</div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1"
      >
        Failure to complete any part of this form may result in rejection of
        the form and your application for IER Physicians Trust funds.
        You will receive notification via email of your award within 120 days.
      </v-col>
    </v-row>
    <!-- Fine Print #1 -->
    <v-divider class="mt-1" style="page-break-after: avoid;" />
    <v-row
      no-gutters
      style="page-break-after: always;"
      class="d-print-block"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1 fine-print"
      >
        <sup>1</sup> If you need additional time to complete and submit this form, notify the Claims Administrator at
        <a
          :href="`mailto:${config.product.email_claims}?subject=${config.product.email_subject_deadline}&body=${config.product.email_body_deadline}`"
          target="_blank"
        >{{ config.product.email_claims }}</a>
        with a detailed explanation of why you cannot submit the form by the deadline.
        The Trustee may grant an extension at their discretion for good cause shown.
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
    <!-- New Page: IERP Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center"
      >
        <h3>INDEPENDENT EMERGENCY ROOM PHYSICIANS TRUST</h3>
      </v-col>
    </v-row>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center"
      >
        <h3>{{ displayAsSingular.toString().toUpperCase() }} FORM</h3>
      </v-col>
    </v-row>
    <!-- Questions -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pl-2"
      >
        <ol
          type="1"
          class="ma-0 pa-0"
        >
          <!-- Question #1 -->
          <li class="pt-1">
            <div style="display: block;">
              The name, address, SSN or Federal Employer Tax Identification Number (“EIN”)
              of the Holder of a IERP Channeled Claim:
            </div>
            <printable-text-field
              ref="claimHolderName"
              v-model="abatementDistributions.claim_holder.name"
              label="Name"
              class="pt-2"
              cols="8"
              :required="true"
              @blur="textField_onBlur($event, (originalData.claim_holder ? originalData.claim_holder.name : null))"
            />
            <address-block
              ref="claimHolderAddress"
              v-model="abatementDistributions.claim_holder"
              :required="true"
              @blur="address_onBlur(abatementDistributions.claim_holder, originalData.claim_holder)"
            />
            <printable-text-field
              ref="claimHolderTaxId"
              v-model="abatementDistributions.claim_holder.tax_id"
              label="SSN or EIN"
              cols="3"
              maxlength="11"
              hint="(Use dashes 111-22-3333 or 12-1234567)"
              :required="true"
              :rules="[config.rules.validTaxId]"
              @blur="textField_onBlur($event, (originalData.claim_holder ? originalData.claim_holder.tax_id : null))"
            />
          </li>
          <!-- Question #2 -->
          <li class="pt-1">
            The contact’s name, address, phone number
            <span
              class="font-weight-bold font-italic"
            >
              and email address
            </span>
            for where notices and IERP Distribution(s) should be sent:
            <!-- Note: Had to use :value here because v-model was interrupting the SameAs_onChange code -->
            <v-checkbox
              v-if="!displayOnly"
              :value="abatementDistributions.notice.is_same_as"
              label="Same as Claimholder"
              class="pa-0 ma-0 pt-1 pl-2 d-print-none"
              dense
              @change="notice_SameAs_onChange"
            />
            <printable-text-field
              ref="noticeName"
              v-model="abatementDistributions.notice.name"
              label="Name"
              class="pt-1"
              cols="8"
              :required="true"
              @blur="textField_onBlur($event, (originalData.notice ? originalData.notice.name : null))"
            />
            <address-block
              ref="noticeAddress"
              v-model="abatementDistributions.notice"
              :required="true"
              @blur="address_onBlur(abatementDistributions.notice, originalData.notice)"
            />
            <printable-text-field
              ref="noticePhone"
              v-model="abatementDistributions.notice.phone"
              label="Phone"
              cols="4"
              maxlength="16"
              :required="true"
              :rules="[config.rules.validPhone]"
              @blur="textField_onBlur($event, (originalData.notice ? originalData.notice.phone : null))"
            />
            <printable-text-field
              ref="noticeEmail"
              v-model="abatementDistributions.notice.email"
              label="email"
              cols="10"
              maxlength="256"
              :required="true"
              :rules="[config.rules.validEmail]"
              @blur="textField_onBlur($event, (originalData.notice ? originalData.notice.email : null))"
            />
          </li>
          <!-- Question #3 -->
          <li class="pt-1">
            <div style="display: block;">
              By filling out this Form, you are deemed to consent to receipt of notice by email.
            </div>
            <div class="d-inline-block ma-0 pa-0">
              If you do not consent to receipt of notice by email, please check this box:
              <v-checkbox
                v-model="abatementDistributions.notice.receipt_by_email"
                class="d-inline-block ma-0 pa-0 pl-4"
                :true-value="false"
                :false-value="true"
                :disabled="displayOnly"
                dense
                @change="onChange"
              />
            </div>
            <div style="display: block;">
              Identify the service area you worked during the years 2001-2019
              <printable-text-field
                ref="currentArea"
                v-model="abatementDistributions.current_service_area"
                label=""
                cols="8"
                maxlength="256"
                class="pt-1"
                :required="true"
                @blur="textField_onBlur($event, originalData.current_service_area)"
              />
            </div>
            <div style="display: block;">
              Do you guarantee the distribution will be used in the Service Area identified above?
            </div>
            <div class="d-inline-block ma-0 pa-0">
              <v-checkbox
                v-model="abatementDistributions.used_in_service_area"
                label="Yes"
                :true-value="true"
                :false-value="false"
                class="d-inline-block ma-0 pa-0 pl-4"
                :disabled="displayOnly"
                dense
                @change="onChange"
              />
              <v-checkbox
                v-model="abatementDistributions.used_in_service_area"
                label="No"
                :true-value="false"
                :false-value="true"
                class="d-inline-block ma-0 pa-0 pl-4"
                :disabled="displayOnly"
                dense
                @change="onChange"
              />
            </div>
          </li>
          <!-- Question #4 -->
          <li>
            <div class="pb-2">
              Please provide a detailed description of the Abatement plan you intend to use for the disbursement.
              Acceptable Abatement uses are shown in
              <a href="#attachment-A">Attachment A</a>.
              Include in your plan the following: plan goals, detailed implementation of steps in the plan
              with an approximation of costs done to the best of your abilities including
              administrative expenses, timeframe for implementation, and how the plan will be evaluated in terms of success.
              The plan can be attached to the Step 2 form.
              We know that administrative expenses will be incurred,
              they should be reasonable with a majority of the funding going to abatement.
              Calculating expenses can be challenging in this process,
              if you need assistance with developing an approximate budget please contact the Claims Administrator and we can and will assist.
              <div class="d-print-none pt-1">
                If you cannot upload your plan in the field below, you can also
                <router-link to="../ContactMaster" target="_blank">mail a hard copy</router-link>
                to an IERP Trustee.
              </div>
            </div>

            <div
              class="d-print-none"
            >
              <v-file-input
                v-if="!displayOnly"
                :key="abatement_plan.documents_count"
                prepend-icon="mdi-file-document-multiple"
                class="pa-0 ma-0"
                dense
                show-size
                multiple
                required
                @change="upload_Abatement_Plan"
              >
                <template v-slot:label>
                  <v-icon
                    v-if="abatement_plan.documents_count <= 0"
                    class="required-icon"
                    x-small
                  >
                    mdi-asterisk
                  </v-icon>
                  {{ (abatement_plan.documents_count > 0 ? 'Abatement Plan uploaded' : 'Upload your Abatement Plan here') }}
                </template>
              </v-file-input>
              <div
                v-else
                class="pl-2 font-weight-bold"
              >
                {{ (abatement_plan.documents_count > 0 ? 'Abatement Plan uploaded' : 'Abatement Plan Not Uploaded') }}
              </div>
            </div>

            <div
              class="ma-0 pa-0 pt-1 pl-4 d-print-none"
            >
              <list-documents
                :v-model="abatement_plan"
                label=""
                :value="abatement_plan"
                :key="abatement_plan.documents_count"
                :path="getPath_to_Abatement_Plan()"
              />
            </div>
          </li>
          <!-- Question #5 -->
          <li class="pt-1">
            <div>
              Please complete the W-9 (link to form
              <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">here</a>) for the Holder of a
              IERP Channeled Claim and attach the W-9 with this {{ displayAsSingular }} Form or
              <router-link to="../ContactMaster" target="_blank">mail a hard copy</router-link>
              to an IERP Trustee.
            </div>
            <v-file-input
              v-if="!displayOnly"
              :key="fileW9.documents_count"
              :label="(fileW9.documents_count > 0 ? 'W-9 uploaded' : 'Upload your W-9 here')"
              accept=".pdf, image/png, image/jpg, image/jpeg, image/bmp"
              prepend-icon="mdi-pdf-box"
              class="pt-1 d-print-none"
              :disabled="fileW9.documents_count > 0"
              dense
              show-size
              @change="upload_W9"
            />
            <div
              v-else
              class="pl-2 font-weight-bold d-print-none"
            >
              {{ (fileW9.documents_count > 0 ? 'W-9 uploaded' : 'W-9 Not Uploaded') }}
            </div>

            <div
              class="ma-0 pa-0 pl-4 d-print-none"
            >
              <list-documents
                :v-model="fileW9"
                label=""
                :value="fileW9"
                :key="fileW9.documents_count"
                :path="getPath_to_W9()"
              />
            </div>
          </li>
          <!-- Question #6 -->
          <li class="pt-1">
            Provide the data requested on the following chart.
            Data, with supporting documentation, will be used, if applicant is confirmed eligible,
            to determine distribution amounts.
          </li>
        </ol>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
  </v-form>
</template>

<script>
import config from '@/configs'
import { Storage } from 'aws-amplify'
import AddressBlock from '@/components/ui/ierp/components/controls/AddressBlock'
import PrintableTextField from '@/components/ui/ierp/components/controls/PrintableTextField'
import ListDocuments from '@/components/ui/ierp/components/controls/ListDocuments'
import { ClaimStatus } from '@/models'

export default {
  name: 'IndependentEmergencyRoomPhysiciansTrust',

  components: {
    AddressBlock,
    PrintableTextField,
    ListDocuments
  },

  data: () => ({
    config,
    formIsValid: false,
    abatement_plan: { documents_count: 0 },
    fileW9: { documents_count: 0 }
  }),

  computed: {
    users () {
      return this.$store.state.user.users
    },
    empty_contact () {
      return this.$store.state.abatementDistribution.empty_contact
    },
    abatementDistributions () {
      return this.$store.state.abatementDistribution.updates
    },
    originalData () {
      return this.$store.state.abatementDistribution.data
    },
    displayAsSingular () {
      return this.$store.state.abatementDistribution.displayAsSingular
    },
    email_subject () {
      return `${this.displayAsSingular} Form`
    },
    email_body () {
      return `Please see attached ${this.displayAsSingular} Form.`
    },
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  methods: {
    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)
        this.$store.dispatch('abatementDistribution/update')
      })
    },

    valid_claim_holder () {
      let isValid = false

      if (this.abatementDistributions.claim_holder) {
        if (this.getLengthOf(this.abatementDistributions.claim_holder.name) > 0 &&
            this.getLengthOf(this.abatementDistributions.claim_holder.tax_id) > 0) {
          isValid = true
        }
      }

      return isValid
    },

    valid_notice () {
      let isValid = false

      if (this.abatementDistributions.notice) {
        if (this.getLengthOf(this.abatementDistributions.notice.name) > 0 &&
            this.getLengthOf(this.abatementDistributions.notice.phone) > 0) {
          isValid = true
        }
      }

      return isValid
    },

    hasError () {
      let isError = true

      if (this.valid_claim_holder() && this.valid_notice() && this.abatement_plan.documents_count > 0) {
        isError = !this.formIsValid
      }

      return isError
    },

    textField_onBlur (value, originalData) {
      if (value !== null && originalData !== null) {
        if (value.toString().trim() !== originalData.toString().trim()) {
          this.onChange(value)
        }
      }
    },

    address_onBlur (updatedData, originalData) {
      if (updatedData.address1 !== originalData.address1 ||
        updatedData.address2 !== originalData.address2 ||
        updatedData.city !== originalData.city ||
        updatedData.state !== originalData.state ||
        updatedData.zip !== originalData.zip) {
        this.onChange(updatedData)
      }
    },

    notice_SameAs_onChange (isSame) {
      if (isSame) {
        this.abatementDistributions.notice = { ...this.abatementDistributions.claim_holder }
      } else {
        this.abatementDistributions.notice = { ...this.empty_contact }
      }

      this.abatementDistributions.notice.is_same_as = isSame

      this.onChange(this.abatementDistributions.notice)
    },

    async putFile (docPath, currentFile, item) {
      await Storage.put(`${docPath}/${currentFile.name}`, currentFile)
        .then((result) => {
          console.info(`File '${currentFile.name}' uploaded successfully`)
          item.documents_count++
        })
        .catch((err) => {
          // TODO: Gracefully display errors to the user
          console.error(err)
          // this.$store.dispatch('error/setError', { name: 'Uploading Document', details: err })
        })
    },

    getPath_to_Abatement_Plan () {
      return `user/${this.users.guid}/AbatementPlan`
    },

    upload_Abatement_Plan (uploadedFiles) {
      if (uploadedFiles) {
        const docPath = this.getPath_to_Abatement_Plan()

        if (docPath !== null) {
          if (docPath.length > 0) {
            for (const currentFile of uploadedFiles) {
              this.putFile(docPath, currentFile, this.abatement_plan)
            }
          }
        }
      }
    },

    getPath_to_W9 () {
      return `user/${this.users.guid}/W-9`
    },

    upload_W9 (uploadedFile) {
      if (uploadedFile) {
        const docPath = this.getPath_to_W9()

        if (docPath !== null) {
          if (docPath.length > 0) {
            this.putFile(docPath, uploadedFile, this.fileW9)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.required-icon {
  vertical-align: top;
  color: #dd0000;
}
</style>
