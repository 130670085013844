<template>
  <div class="print-only">
    <table
      class="text-center d-print-block"
      style="width: 100%;"
    >
      <tr>
        <td
          class="text-left"
          style="width: 50%; vertical-align: top;"
        >
          <table
            class="text-center print-table"
            style="width: 95%; vertical-align: top;"
          >
            <thead>
              <tr style="vertical-align: top;">
                <th class="text-left print-table font-weight-bold" style="width: 30%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[0].print_text }}
                </th>
                <th class="text-left print-table font-weight-bold" style="width: 45%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[1].print_text }}
                </th>
                <th class="text-left print-table font-weight-bold" style="width: 25%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[2].print_text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in print_distribution_amounts_1"
                :key="item.year"
              >
                <td class="text-left print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">{{ item.year }}</td>
                <td class="text-center print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">
                  {{ (item.proof ? 'P' : (item.selected ? 'X' : '')) }}
                </td>
                <td class="text-right print-table" style="font-size: 14px; padding: 2px 15px 2px 5px;">{{ item.hours }}</td>
              </tr>
            </tbody>
          </table>
        </td>
        <td
          class="text-right"
          style="width: 50%; vertical-align: top;"
        >
          <table
            class="text-center print-table"
            style="width: 95%; vertical-align: top;"
          >
            <thead>
              <tr style="vertical-align: top;">
                <th class="text-left print-table font-weight-bold" style="width: 30%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[0].print_text }}
                </th>
                <th class="text-left print-table font-weight-bold" style="width: 45%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[1].print_text }}
                </th>
                <th class="text-left print-table font-weight-bold" style="width: 25%; font-size: 10px; padding: 2px 5px 2px 5px;">
                  {{ distribution_headers[2].print_text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in print_distribution_amounts_2"
                :key="item.year"
              >
                <td class="text-left print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">{{ item.year }}</td>
                <td class="text-center print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">
                  {{ (item.proof ? 'P' : (item.selected ? 'X' : '')) }}
                </td>
                <td class="text-right print-table" style="font-size: 14px; padding: 2px 15px 2px 5px;">{{ item.hours }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr><td class="pa-1 print-table" colspan="3">&nbsp;</td></tr>
              <tr style="vertical-align: top;">
                <th class="text-left print-table font-weight-bold" style="font-size: 10px; padding: 5px 5px 5px 5px;">Totals</th>
                <th class="text-left print-table font-weight-bold" style="font-size: 10px; padding: 5px 5px 5px 5px;">
                  Total #<br />Years Worked as IERP
                </th>
                <th class="text-left print-table font-weight-bold" style="font-size: 10px; padding: 5px 5px 5px 5px;">
                  Total # Hours Worked as IERP
                </th>
              </tr>
              <tr>
                <td class="text-left print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">&nbsp;</td>
                <td class="text-center print-table" style="font-size: 14px; padding: 2px 5px 2px 5px;">{{ totalYears }}</td>
                <td class="text-right print-table" style="font-size: 14px; padding: 2px 15px 2px 5px;">{{ totalHours }}</td>
              </tr>
            </tfoot>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PrintDistributionAmountsTable',

  data () {
    return {
      year_break_for_print: 2013
    }
  },

  computed: {
    ...mapGetters({
      totalYears: 'abatementDistribution/getTotalYears',
      totalHours: 'abatementDistribution/getTotalHours'
    }),
    distribution_headers () {
      return this.$store.state.abatementDistribution.headers
    },
    distribution_amounts () {
      return this.$store.state.abatementDistribution.updates.amounts
    },
    print_distribution_amounts_1 () {
      const amounts = []

      for (const currentData of this.distribution_amounts) {
        if (parseInt(currentData.year, 0) < this.year_break_for_print) {
          amounts.push({ ...currentData })
        }
      }

      return amounts
    },
    print_distribution_amounts_2 () {
      const amounts = []

      for (const currentData of this.distribution_amounts) {
        if (parseInt(currentData.year, 0) >= this.year_break_for_print) {
          amounts.push({ ...currentData })
        }
      }

      return amounts
    }
  }
}
</script>
