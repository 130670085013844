<template>
  <div>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <div class="pt-4">
          I,
          <span
            :class="`${(getLengthOf(abatementDistributions.claim_holder.name) > 0 ? 'text-decoration-underline' : null)} pa-0 ma-0`"
          >
            &nbsp;{{ (getLengthOf(abatementDistributions.claim_holder.name) > 0 ? abatementDistributions.claim_holder.name : '_______________________________________________________________________________') }}
          </span>,
          (name) certify all
          information provided on this IER Physicians {{ displayAsSingular }} Form to be true to
          the best of my knowledge.
        </div>
        <div class="pt-4">
          <signature-line
            :value="abatementDistributions.claim_holder.signature"
            label="Signature:"
            @change="claimholderSignature_onChange"
          />
        </div>
        <h3 class="pt-6 text-center">
          You will receive notification as to whether you will receive an {{ displayAsSingular }} and
          the amount within 120 days of submission of this {{ displayAsSingular }} Form.
        </h3>
        <div class="pt-3">
          Please note:  If you receive an {{ displayAsSingular }} it will be distributed to the
          address provided above within 30 days of receipt of the signed and completed
          <span class="font-italic">
            IER Physicians Trust Authorized Recipient’s Instruction and Agreement Form,
            which will be provided with the notification letter.
            A copy of that form is attached hereto as <a href="#attachment-C">Attachment C</a>.
            If you cannot agree to and abide by all instructions in that form,
            you will forfeit any {{ displayAsSingular.toString().toLowerCase() }}.
          </span>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
  </div>
</template>

<script>
import SignatureLine from '@/components/ui/ierp/components/controls/SignatureLine'

export default {
  name: 'ClaimholderSignature',

  components: {
    SignatureLine
  },

  computed: {
    abatementDistributions () {
      return this.$store.state.abatementDistribution.updates
    },
    displayAsSingular () {
      return this.$store.state.abatementDistribution.displayAsSingular
    }
  },

  methods: {
    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)
        this.$store.dispatch('abatementDistribution/update')
      })
    },

    hasError () {
      return !this.abatementDistributions.claim_holder.signature
    },

    claimholderSignature_onChange (evt) {
      this.abatementDistributions.claim_holder.signature = evt
      this.onChange(evt)
    }
  }
}
</script>

<style scoped>

</style>
