<template>
  <div class="ma-0 pa-0">
    <div
      :class="(displayOnly ? 'd-inline-block' : 'print-only')"
    >
      <span class="font-weight-bold">{{ (label.length > 0 ? `${label}:` : '') }}</span>
      <span class="pl-1">{{ internalValue }}</span>
      <v-divider class="mb-1" style="width: 125px;" />
      <!--
      <span style="border-bottom: 1px solid black;">
        {{ ( getLengthOf(internalValue) > 0 ? `&nbsp;${internalValue}&nbsp;` : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' ) }}
      </span>
      -->
    </div>
    <div
      v-if="!displayOnly"
      class="d-print-none"
    >
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="internalValue"
            append-icon="mdi-calendar"
            readonly
            dense
            clearable
            v-bind="attrs"
            v-on="on"
            @blur="onBlur"
          >
            <template v-slot:label>
              <v-icon
                v-if="required"
                class="required-icon"
                x-small
              >
                mdi-asterisk
              </v-icon>
              {{ label }}
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="internalValue"
          @input="menu = false"
          @change="onChange"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import config from '@/configs'
import { ClaimStatus } from '@/models'

export default {
  name: 'PrintableDateField',

  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Date'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: null
    },
    rules: {
      type: Array
    }
  },

  data () {
    return {
      internalValue: this.value,
      config,
      menu: false
    }
  },

  computed: {
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  watch: {
    internalValue: {
      handler (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', this.internalValue)
      }
    },
    value: {
      handler (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      }
    }
  },

  methods: {
    onBlur (dataItem) {
      this.$nextTick(() => {
        this.$emit('blur', dataItem)
      })
    },

    onChange (dataItem) {
      this.$nextTick(() => {
        this.$emit('change', dataItem)
      })
    },

    prepRules () {
      const allRules = []

      if (this.required && !this.disabled) {
        allRules.push(this.config.rules.required)
      }

      if (this.rules) {
        for (const parentRule of this.rules) {
          allRules.push(parentRule)
        }
      }

      return allRules
    }
  }
}
</script>

<style scoped>

</style>
