import mutations from './mutations'
import actions from './actions'

// state initial values
const state = {
  users: {
    authState: undefined,
    email: null,
    guid: null,
    isAdmin: false
  },
  empty: {
    authState: undefined,
    email: null,
    guid: null,
    isAdmin: false
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
