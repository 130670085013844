<template>
  <v-dialog
    v-model="displayDialog"
    max-width="500px"
    persistent
    @keydown.esc.prevent="cancel_onClick"
    @keydown.enter.prevent="ok_onClick"
  >
    <v-card>
      <v-card-title>
        <slot name="title" />
      </v-card-title>

      <v-divider />

      <v-card-text class="font-weight-medium text-body-1">
        <slot name="message" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="secondary"
          @click="cancel_onClick"
        >
          Cancel
        </v-btn>

        <v-btn
          color="primary"
          @click="ok_onClick"
        >
          OK
        </v-btn>

        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmationDialog',

  data () {
    return {
      displayDialog: null
    }
  },

  watch: {
    displayDialog (val) {
      val || this.cancel_onClick()
    }
  },

  methods: {
    setDisplayDialog (val) {
      this.displayDialog = val
    },

    cancel_onClick () {
      this.setDisplayDialog(false)
      this.$emit('cancel')
    },

    ok_onClick () {
      this.setDisplayDialog(false)
      this.$emit('confirm')
    }
  }
}
</script>
