<template>
  <v-sheet color="transparent">
    <ToolbarMaster />
    <Faq />
    <FooterMaster />
  </v-sheet>
</template>

<script>
import ToolbarMaster from '@/components/ui/ierp/toolbar/ToolbarMaster'
import Faq from '@/components/ui/ierp/pages/Faq.vue'
import FooterMaster from '@/components/ui/ierp/pages/FooterMaster.vue'

export default {
  components: {
    ToolbarMaster,
    Faq,
    FooterMaster
  }
}
</script>
