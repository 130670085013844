<template>
  <div>
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: always;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <div id="attachment-A" class="font-italic pb-1">
          Attachment A
        </div>
        <h4 class="font-weight-bold text-center pb-1">
          IER PHYSICIAN AUTHORIZED ABATEMENT PURPOSES.
        </h4>
        <div class="text-left pb-1">
          The IERP Trust Distribution Plan states:
        </div>
        <div class="text-left pb-1">
          (a) All net funds (after the deduction of all legal fees and litigation expenses, as described herein,
          and in the IER Physician Trust Agreement) distributed to IER Physician Authorized Recipients shall be
          used solely and exclusively for OUD abatement programs, whether currently existing or newly initiated.
          As a condition of receiving an IER Physician {{ displayAsSingular }}, each IER Physician
          Authorized Recipient must submit to the Trustee on its IER Physician {{ displayAsSingular }} Form
          a written statement that all funds will be spent only for one or more of the following
          IER Physician Authorized Abatement Purposes:
        </div>
        <div class="text-left pl-4 pb-1">
          (i) Providing continuing professional education in Medication Assisted Treatment (“MAT”)
          of opiate use disorder and obtainment of the waiver as required by DEA for prescribing of
          medications for MAT and screening and referral to treatment of substance abuse disorder;
        </div>
        <div class="text-left pl-4 pb-1">
          (ii) Providing training in alternatives to opiate pain relief and non-opioid treatment modalities;
        </div>
        <div class="text-left pl-4 pb-1">
          (iii) Providing training in use and distribution of Naloxone kits and instruction to patients upon discharge;
        </div>
        <div class="text-left pl-4 pb-1">
          (iv) MAT Programs: Establishing and implementing a MAT program or to continue,
          complete and/or implement an existing MAT program already under development.
          Program must include training of “team leaders”, education on proper coding,
          and programs to coordinate with mobile crisis;
        </div>
        <div class="text-left pl-4 pb-1">
          (v) Promote solutions to reduce the supply and demand of opioids through implementation of
          programs such as, but not limited to: alternatives to prescribing opioids,
          implementing screening tools, providing training in alternatives to prescribing opioids,
          and registration with the state Prescription Drug Monitoring Programs (PDMPs) or
          voluntary comparison of opioid prescription practices among peer physicians and
          providing proof of lower levels in MMEs (morphine milligram equivalents) post intervention;
        </div>
        <div class="text-left pl-4 pb-1">
          (vi) Promoting and developing telemedicine for delivery of medication assisted treatment;
        </div>
        <div class="text-left pl-4 pb-1">
          (vii) Community outreach to decrease opioid abuse including, but not limited to,
          education programs with local schools and coordination with substance abuse treatment centers;
        </div>
        <div class="text-left pl-4 pb-1">
          (viii) Providing training information through medical schools and residency programs to
          decrease opioid abuse including, but not limited to, training to receive a DEA X-waiver,
          screening for substance use disorders, and treatment of substance use disorders;
        </div>
        <div class="text-left pl-4 pb-1">
          (ix) Train and support case managers or peer support specialists in Emergency Departments to
          facilitate transitions to care upon discharge such as addiction treatment, recovery support,
          harm reduction services, and other community supports (e.g., housing, transportation,
          job skills training, employment-related services, etc.);
        </div>
        <div class="text-left pl-4 pb-1">
          (x) Train and support networks of addiction treatment providers, primary care providers,
          harm reduction, and other community supports to receive and continue treating and
          supporting patients upon discharge, including Federally Qualified Health Centers and
          other providers that patients with little or no health insurance coverage.
          Develop and maintain an online portal where providers can access information
          related to the available resources in their communities.
        </div>
        <div class="text-left pb-1">
          (b) In addition, the IER Physician Trust shall make IER Physician {{ displayAsPlural }} to
          IER Physician Authorized Recipients exclusively for IER Physician Authorized Abatement Purposes
          within each IER Physician Authorized Recipients’ respective Service Area identified in the claim.
        </div>
        <div class="text-left">
          (c) To the extent any Holder of an IER Physician Channeled Claim that is otherwise an IER Physician
          Authorized Recipient does not comply with this § 7, such Holder of an IER Physician Channeled Claim
          shall not be an IER Physician Authorized Recipient and shall be disqualified from receiving IER Physician
          {{ displayAsPlural }}, notwithstanding any other eligibility determination pursuant to other sections
          or procedures set forth herein or in the other IER Physician Trust Document.
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
  </div>
</template>

<script>
export default {
  name: 'AttachmentA',

  computed: {
    displayAsSingular () {
      return this.$store.state.abatementDistribution.displayAsSingular
    },
    displayAsPlural () {
      return this.$store.state.abatementDistribution.displayAsPlural
    }
  }
}
</script>

<style scoped>

</style>
