import mutations from './mutations'
import actions from './actions'
import getters from './getters'

// state initial values
const state = {
  displayAsSingular: 'Abatement Distribution',
  displayAsPlural: 'Abatement Distributions',
  lastReload: {
    datetime: null,
    thresholdSeconds: 2
  },
  data: {},
  updates: null,
  headers: [
    {
      text: 'Year',
      print_text: 'Year',
      align: 'left',
      value: 'year',
      sortable: false,
      filterable: false,
      width: '10%',
      class: 'primary--text font-weight-bold text-body-1 text-no-wrap'
    },
    {
      text: 'Select each year you worked as an Independent ER Physician treating OUD patients',
      print_text: 'Place an X by each year you worked as an Independent ER Physician treating OUD patients.  Place a “P” if you already provided record proof',
      align: 'left',
      value: 'selected',
      sortable: false,
      filterable: false,
      width: '30%',
      class: 'primary--text font-weight-bold text-body-1'
    },
    {
      text: 'Number of hours worked each year',
      print_text: 'Number of hours worked each year',
      align: 'left',
      value: 'hours',
      sortable: false,
      filterable: false,
      width: '20%',
      class: 'primary--text font-weight-bold text-body-1'
    },
    {
      text: 'Provide Proof',
      print_text: 'Provide Proof',
      align: 'left',
      value: 'proof',
      sortable: false,
      filterable: false,
      width: '40%',
      class: 'primary--text font-weight-bold text-body-1'
    }
  ],
  empty_contact: {
    is_same_as: false,
    date: null,
    name: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    tax_id: null,
    phone: null,
    email: null,
    receipt_by_email: true,
    signature: false
  },
  empty: {
    claim_holder: null,
    notice: null,
    completer: null,
    current_service_area: '',
    used_in_service_area: null,
    affidavit: null,
    amounts: [
      { year: '2001', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2002', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2003', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2004', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2005', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2006', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2007', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2008', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2009', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2010', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2011', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2012', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2013', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2014', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2015', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2016', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2017', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2018', selected: false, proof: false, hours: null, documents_count: 0 },
      { year: '2019', selected: false, proof: false, hours: null, documents_count: 0 }
    ]
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
