<template>
  <div>
    <v-data-table
      ref="tableIndividuals"
      :headers="headers"
      :items="individuals"
      sort-by="last_name"
      class="elevation-2"
      :loading="individualIsBusy"
      :search="search"
      show-expand
      @dblclick:row="view_onClick"
    >
      <template v-slot:no-data>
        <h2
          class="primary--text"
        >
          No {{ displayAsPlural }} Available
        </h2>
      </template>

      <template v-slot:no-results>
        <h3
          class="primary--text"
        >
          No matching {{ displayAsSingular }} data found containing '{{ search }}'
        </h3>
      </template>

      <template v-slot:loading>
        <h2
          class="primary--text"
        >
          Loading {{ displayAsPlural }}... Please wait
        </h2>
      </template>

      <template v-slot:top>
        <v-container
          class="pa-0 ma-0"
        >
          <!-- Title Row -->
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            />

            <v-col
              cols="12"
              sm="8"
              class="pa-0 ma-0 text-center"
            >
              <h2
                class="primary--text"
              >
                {{ displayAsPlural }}
              </h2>
            </v-col>

            <v-col
              cols="12"
              sm="2"
              class="pa-0 ma-0 text-center"
            />
          </v-row>
          <!-- Search Row -->
          <v-row
            no-gutters
            class="pa-0 ma-0"
          >
            <v-col
              cols="12"
              sm="10"
              class="pa-0 ma-0 text-center"
            >
              <v-text-field
                v-if="getLengthOf(individuals) > 0"
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="pl-10 pr-10"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="getLengthOf(item.guid) > 0"
          small
          color="primary"
          class="mr-2"
          @click="view_onClick($event, { item })"
        >
          <!--
          mdi-file-eye
          mdi-monitor-eye
          -->
          mdi-file-eye
        </v-icon>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td
          class="pt-4 pb-4"
          :colspan="getLengthOf(headers) + 1"
        >
          <v-data-table
            :headers="detailHeaders"
            :items="[item]"
            :hide-default-footer="true"
            class="elevation-2"
          >
            <template v-slot:top>
              <h2
                class="primary--text font-weight-bold text-center pl-1 pt-2 pr-1"
              >
                {{ item.first_name }} {{ item.last_name }} - {{ displayAsSingular }} Details
              </h2>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  sm="6"
                  class="primary--text font-weight-bold text-body-1 text-center"
                >
                  Step 1
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  class="primary--text font-weight-bold text-body-1 text-center"
                >
                  Step 2
                </v-col>
              </v-row>
            </template>

            <template #header.step_1_revised_deadline_date="{ header }">
              <v-col
                cols="12"
                sm="12"
                class="primary--text font-weight-bold text-body-1 text-center step-border"
              >
                <span class="mr-3">{{ header.text }}</span>
              </v-col>
            </template>

            <template #item.step_1_revised_deadline_date="{ item }">
              <v-col
                cols="12"
                sm="12"
                class="primary--text font-weight-bold text-body-1 text-center step-border"
              >
                <span class="mr-3">{{ item.step_1_revised_deadline_date }}</span>
              </v-col>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DataStore } from '@aws-amplify/datastore'
import { IndividualVoter } from '@/models'

export default {
  name: 'IndividualsTable',

  data: () => ({
    search: null,
    headers: [
      {
        text: 'First Name',
        value: 'first_name',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '28%',
        sortable: true,
        filterable: true
      },
      {
        text: 'Last Name',
        value: 'last_name',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '30%',
        sortable: true,
        filterable: true
      },
      {
        text: 'Step 1 Status',
        value: 'step_1_claim_status',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '20%',
        sortable: true,
        filterable: true
      },
      {
        text: 'Step 2 Status',
        value: 'step_2_claim_status',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '20%',
        sortable: true,
        filterable: true
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        class: 'primary--text font-weight-bold',
        width: '1%',
        sortable: false,
        filterable: false
      },
      {
        text: '',
        value: 'data-table-expand',
        width: '1%'
      }
    ],
    detailHeaders: [
      {
        text: 'Mailed',
        value: 'step_1_form_mailed_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Received',
        value: 'step_1_form_received_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Deadline',
        value: 'step_1_trustee_deadline_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Revised',
        value: 'step_1_revised_deadline_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Mailed',
        value: 'step_2_form_mailed_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Received',
        value: 'step_2_form_received_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Deadline',
        value: 'step_2_form_deadline_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      },
      {
        text: 'Revised',
        value: 'step_2_revised_deadline_date',
        align: 'start',
        class: 'primary--text font-weight-bold text-body-1',
        cellClass: 'font-weight-medium text-body-1',
        width: '12.5%',
        sortable: false
      }
    ]
  }),

  computed: {
    users () {
      return this.$store.state.user.users
    },
    individuals () {
      return this.$store.state.individual.all
    },
    individualIsBusy () {
      return this.$store.state.individual.isBusy
    },
    displayAsSingular () {
      return this.$store.state.individual.displayAsSingular
    },
    displayAsPlural () {
      return this.$store.state.individual.displayAsPlural
    }
  },

  created() {
    this.subscription = DataStore.observe(IndividualVoter).subscribe(() => {
      // console.info('IndividualVoter sub')
      this.$store.dispatch('individual/retrieveAll')
    })

    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'user/setUser':
        this.routeToStep2()
        break
      default:
        break
      }
    })
  },

  beforeDestroy () {
    this.unsubscribe()
  },

  methods: {
    routeToStep2 () {
      if (this.users) {
        if ({}.hasOwnProperty.call(this.users, 'email') && {}.hasOwnProperty.call(this.users, 'guid')) {
          if (this.users.email.trim().length > 0 && this.users.guid.trim().length > 0) {
            this.$store.dispatch('individual/retrieve')
            this.$store.dispatch('abatementDistribution/retrieve')

            this.$nextTick(() => {
              /* TODO: Open in a new tab, data is not there for some reason but would be nice if this opened in a separate tab
              const routeData = this.$router.resolve({ name: 'Abatement Distribution Form' })

              window.open(routeData.href, '_blank')
               */
              this.$router.push({ name: 'Abatement Distribution Form' })
            })
          }
        }
      }
    },

    view_onClick (event, { item }) {
      if (this.getLengthOf(item.guid) > 0) {
        this.$store.dispatch('user/setUser', item)
      } else {
        const showToast = {
          title: `${this.displayAsSingular} Selected`,
          messages: [`${item.first_name} ${item.last_name} has not logged in for Step 2.`],
          timeout: 10000,
          variant: 'info'
        }

        this.$store.dispatch('user/showToast', showToast)
      }
    }
  }
}
</script>

<style scoped>
  .step-border {
    border-right: 1px solid #0096c7;
  }
</style>
