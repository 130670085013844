<template>
  <div class="ma-0 pa-0">
    <div
      :class="(displayOnly ? 'd-inline-block' : 'print-only')"
    >
      <table class="pa-0 ma-0">
        <tr>
          <td class="font-weight-bold" style="width: 55px;">Address:</td>
          <td>{{ internalValue.address1 }}</td>
        </tr>
        <tr v-if="getLengthOf(internalValue.address2) > 0">
          <td>&nbsp;</td>
          <td>{{ internalValue.address2 }}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td>
            {{ internalValue.city }}<span v-if="getLengthOf(internalValue.city) > 0 && getLengthOf(internalValue.state) > 0">,</span>
            {{ internalValue.state }} {{ internalValue.zip }}
          </td>
        </tr>
      </table>
      <v-divider class="mb-1" />
    </div>
    <div
      v-if="!displayOnly"
      class="d-print-none"
    >
      <v-container
        class="pa-0 ma-0"
      >
        <!-- Address 1 -->
        <v-row
          no-gutters
          :class="(addressHasError() ? 'mb-2' : null)"
        >
          <v-col
            cols="12"
            sm="8"
          >
            <v-text-field
              ref="textAddress1"
              v-model="internalValue.address1"
              class="control-address"
              :rules="prepAddress1Rules()"
              :maxlength="(maxlength ? maxlength : 50)"
              dense
              clearable
              @blur="onBlur(internalValue)"
              @change="onChange(internalValue)"
            >
              <template v-slot:label>
                <v-icon
                  v-if="required"
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                Address 1
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- Address 2 -->
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            sm="8"
          >
            <v-text-field
              ref="textAddress2"
              v-model="internalValue.address2"
              label="Address 2"
              class="control-address"
              :maxlength="(maxlength ? maxlength : 50)"
              dense
              clearable
              @blur="onBlur(internalValue)"
              @change="onChange(internalValue)"
            />
          </v-col>
        </v-row>
        <!-- Zip -->
        <v-row
          no-gutters
          :class="(zipHasError() ? 'mb-2' : null)"
        >
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              ref="textZip"
              v-model="internalValue.zip"
              class="control-zip"
              :rules="prepZipRules()"
              :maxlength="(maxlength ? maxlength : 10)"
              dense
              clearable
              @blur="zip_onBlur(internalValue)"
            >
              <template v-slot:label>
                <v-icon
                  v-if="required"
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                Zip
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <!-- City / State -->
        <v-row
          no-gutters
          :class="(cityStateHasError() ? 'mb-2' : null)"
        >
          <v-col
            cols="12"
            sm="5"
          >
            <v-text-field
              ref="textCity"
              v-model="internalValue.city"
              class="control-city"
              :rules="prepCityRules()"
              :maxlength="(maxlength ? maxlength : 50)"
              dense
              clearable
              @blur="onBlur(internalValue)"
              @change="onChange(internalValue)"
            >
              <template v-slot:label>
                <v-icon
                  v-if="required"
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                City
              </template>
            </v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="2"
            offset-sm="1"
          >
            <v-text-field
              ref="comboboxState"
              v-model="internalValue.state"
              min-width="200px"
              class="control-state"
              :rules="prepStateRules()"
              :maxlength="(maxlength ? maxlength : 2)"
              dense
              clearable
              @blur="onBlur(internalValue)"
              @change="onChange(internalValue)"
            >
              <template v-slot:label>
                <v-icon
                  v-if="required"
                  class="required-icon"
                  x-small
                >
                  mdi-asterisk
                </v-icon>
                State
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import config from '@/configs'
import { ClaimStatus } from '@/models'

export default {
  name: 'AddressBlock',

  props: {
    value: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 50
    }
  },

  data () {
    return {
      internalValue: this.value,
      config
    }
  },

  computed: {
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  watch: {
    internalValue: {
      handler (val, oldVal) {
        this.$emit('input', this.internalValue)
      },
      deep: true
    },
    value: {
      handler (val, oldVal) {
        this.internalValue = val
      },
      deep: true
    }
  },

  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'app/successfulZipLookup':
        this.onBlur(this.internalValue)
        break
      default:
        break
      }
    })
  },

  beforeDestroy () {
    this.unsubscribe()
  },

  methods: {
    addressHasError () {
      let isError = false

      if (this.$refs.textAddress1) {
        isError = this.$refs.textAddress1.hasError
      }

      return isError
    },

    zipHasError () {
      let isError = false

      if (this.$refs.textZip) {
        isError = this.$refs.textZip.hasError
      }

      return isError
    },

    cityStateHasError () {
      let isError = false

      if (!isError && this.$refs.textCity) {
        isError = this.$refs.textCity.hasError
      }

      if (!isError && this.$refs.comboboxState) {
        isError = this.$refs.comboboxState.hasError
      }

      return isError
    },

    hasError () {
      let isError = false

      if (!isError) {
        isError = this.addressHasError()
      }

      if (!isError) {
        isError = this.zipHasError()
      }

      if (!isError) {
        isError = this.cityStateHasError()
      }

      return isError
    },

    onBlur (dataItem) {
      this.$nextTick(() => {
        this.$emit('blur', dataItem)
      })
    },

    onChange (dataItem) {
      this.$nextTick(() => {
        this.$emit('change', dataItem)
      })
    },

    zip_onBlur (dataItem) {
      if (dataItem) {
        if ({}.hasOwnProperty.call(dataItem, 'zip')) {
          if (dataItem.zip && dataItem.zip.length > 0) {
            this.$store.dispatch('app/retrieveCityState', dataItem)
          }
        }
      }
    },

    prepAddress1Rules () {
      const rules = []

      if (this.required && !this.disabled) {
        rules.push(this.config.rules.required)
      }
      rules.push(this.config.rules.validAddress1)

      return rules
    },

    prepZipRules () {
      const rules = []

      if (this.required && !this.disabled) {
        rules.push(this.config.rules.required)
      }
      rules.push(this.config.rules.validZip)

      return rules
    },

    prepCityRules () {
      const rules = []

      if (this.required && !this.disabled) {
        rules.push(this.config.rules.required)
      }
      rules.push(this.config.rules.validCity)

      return rules
    },

    prepStateRules () {
      const rules = []

      if (this.required && !this.disabled) {
        rules.push(this.config.rules.required)
      }
      rules.push(this.config.rules.validState)

      return rules
    }
  }
}
</script>

<style lang="scss" scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
  .control-address {
    min-width: 375px;
  }
  .control-zip {
    min-width: 75px;
  }
  .control-city {
    min-width: 75px;
  }
  .control-state {
    min-width: 75px;
  }
</style>
