<template>
  <div class="ma-0 pa-0 d-inline-block">
    <div
      :class="(confirmationCheckbox ? 'print-only' : 'd-inline-block')"
    >
      <span>
        {{ (label.length > 0 ? `${label}` : '') }} {{ getSignatureSpace() }}
      </span>

      <span
        v-if="displayDate"
        class="pl-2"
      >
        Date: ___________________
      </span>
    </div>

    <div
      v-if="confirmationCheckbox"
      class="d-print-none d-inline-block ma-0 pa-0"
    >
      <v-icon
        v-if="required"
        class="d-inline-block required-icon"
        x-small
      >
        mdi-asterisk
      </v-icon>
      I understand the terms for this {{ (label.length > 0 ? `${label}` : '') }}
      <v-checkbox
        :value="value"
        class="d-inline-block ma-0 pa-0 pl-1"
        dense
        :true-value="true"
        :false-value="false"
        :disabled="displayOnly"
        @change="signature_onChange"
      />
    </div>
  </div>
</template>

<script>
import { ClaimStatus } from '@/models'

export default {
  name: 'SignatureLine',

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      default: 'Signature:',
      required: true
    },
    displayDate: {
      type: Boolean,
      default: true,
      required: false
    },
    required: {
      type: Boolean,
      default: true,
      required: false
    },
    confirmationCheckbox: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  computed: {
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  methods: {
    getSignatureSpace () {
      const signatureCharacter = '_'
      const lineLength = 60 - (this.getLengthOf(this.label) - 10)
      const signatureSpace = signatureCharacter.padEnd(lineLength, signatureCharacter)

      return signatureSpace
    },

    signature_onChange (evt) {
      this.$nextTick(() => {
        this.$emit('change', evt)
      })
    }
  }
}
</script>

<style scoped>
  .required-icon {
    vertical-align: top;
    color: #dd0000;
  }
</style>
