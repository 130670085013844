<template>
  <div
    v-if="userAuthState !== 'signedin'"
  >
    <amplify-authenticator
      username-alias="email"
    >
      <amplify-sign-in
        slot="sign-in"
        :header-text="`${config.product.name} - Sign In`"
        :hide-sign-up="true"
        :username-alias="signInConfig.usernameAlias"
        :formFields="signInConfig.formFields"
      />

      <amplify-forgot-password
        slot="forgot-password"
        :header-text="`${config.product.name} - Reset your password`"
        :username-alias="signInConfig.usernameAlias"
      />
    </amplify-authenticator>
  </div>
</template>

<script>
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import config from '@/configs'

export default {
  name: 'LoginDialog',

  data: () => ({
    unsubscribeAuth: undefined,
    signInConfig: {
      usernameAlias: 'email',
      formFields: [
        {
          type: 'email',
          label: 'Email',
          placeholder: 'Email',
          hint: 'Enter Your Email',
          required: true
        },
        {
          type: 'password',
          label: 'Password',
          placeholder: 'Enter Your Password',
          hint: 'Password',
          required: true
        }
      ]
    },
    config
  }),

  computed: {
    userAuthState () {
      let authState = ''

      if (this.$store.state.user.users !== null) {
        if ({}.hasOwnProperty.call(this.$store.state.user.users, 'authState')) {
          authState = this.$store.state.user.users.authState
        }
      }

      return authState
    }
  },

  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.$store.dispatch('user/setAuth', { authState: authState, authData: authData })
    })
  },

  beforeDestroy () {
    this.unsubscribeAuth()
  }
}
</script>

<style scoped>

</style>
