import icons from './icons'
import theme from './theme'
import analytics from './analytics'
import rules from './rules'

export default {
  // product display information
  product: {
    name: 'IERP Trust',
    version: 'v1.0.0',
    url: 'www.ierptrust.com',
    email_claims: 'ClaimsAdministrator@IERPtrust.com',
    email_trustee: 'Trustee@IERPtrust.com',
    email_subject_deadline: 'IERP Request for Extension',
    email_body_deadline: 'Please consider the missed deadline and reason explained below...',
    mailing_address_1: '1121 Park West Blvd.',
    mailing_address_2: 'Suite B #186',
    mailing_city: 'Mt. Pleasant',
    mailing_state_cd: 'SC',
    mailing_zip: '29466-7122'
  },

  // icon libraries
  icons,

  // theme configs
  theme,

  // analytics configs
  analytics,

  // validation rules
  rules
}
