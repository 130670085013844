<template>
  <v-app>
    <login-dialog
      ref="loginDialog"
    />

    <!-- Abatement Distribution Form - Logged In -->
    <div v-if="userAuthState === 'signedin'">
      <v-fade-transition mode="out-in">
        <v-container>
          <independent-emergency-room-physicians-trust
            ref="ierpTrust"
            @change="refreshDisable"
          />

          <distribution-amounts
            ref="distributionAmounts"
            @change="refreshDisable"
          />

          <claimholder-signature
            ref="claimholderSignature"
          />

          <attachment-a
            ref="attachmentA"
          />

          <attachment-b
            ref="attachmentB"
          />

          <attachment-c
            ref="attachmentC"
          />

          <logged-in-footer
            v-if="userAuthState === 'signedin'"
          >
            <template #beforeSignOut>
              <v-btn
                v-if="users.isAdmin"
                color="primary"
                min-width="150"
                class="mr-2 font-weight-medium text-body-1"
                to="/protected/AdminView"
              >
                Admin View
              </v-btn>
            </template>

            <template #afterSignOut>
              <v-btn
                color="secondary"
                min-width="150"
                class="ml-1 mr-1 font-weight-medium text-body-1"
                @click="generatePDF"
              >
                Print
              </v-btn>
              <v-btn
                v-if="!users.isAdmin"
                color="primary"
                min-width="150"
                class="ml-1 font-weight-medium text-body-1"
                @click="submit_onClick"
                :disabled="disableSubmit"
              >
                SUBMIT
              </v-btn>
            </template>
          </logged-in-footer>
        </v-container>
      </v-fade-transition>

      <confirmation-dialog
        ref="submitConfirmationDialog"
        @confirm="confirm_onClick"
      >
        <template #title>
          <h2 class="primary--text ma-0 pa-0">
            Confirm Submit
          </h2>
        </template>

        <template #message>
          <div class="ma-0 pa-0 pt-2">
            Are you sure you want to submit this form?
          </div>

          <div class="ma-0 pa-0 pt-2">
            Once submitted this form is final.
            Any request for changes must be approved by the Claims Administrator.
            Changes may be denied.
          </div>
        </template>
      </confirmation-dialog>
    </div>
  </v-app>
</template>

<style lang="scss">
:root {
  --amplify-primary-color: #0096c7;
  --amplify-primary-contrast: var(–amplify-white);
  --amplify-primary-tint: #0096c7;
  --amplify-primary-shade: #0096c7;
  --amplify-secondary-color: #152939;
  --amplify-secondary-contrast: var(–amplify-white);
  --amplify-secondary-tint: #31465f;
  --amplify-secondary-shade: #1F2A37;
  --amplify-tertiary-color: #5d8aff;
  --amplify-tertiary-contrast: var(–amplify-white);
  --amplify-tertiary-tint: #7da1ff;
  --amplify-tertiary-shade: #537BE5;
  --amplify-grey: #828282;
  --amplify-light-grey: #c4c4c4;
  --amplify-white: #ffffff;
  --amplify-red: #dd3f5b;
  /*

  --amplify-primary-color: var(--v-primary-base);
  --amplify-primary-tint: #ffac31;
  --amplify-primary-shade: #e88b01;
  */
}

tr:nth-child(even) {background-color: #f2f2f2;}

.print-table {
  border: 1px solid black;
  border-collapse: collapse;
}
</style>

<script>
import { ClaimStatus } from '@/models'
import LoginDialog from '@/components/ui/ierp/components/controls/LoginDialog'
import IndependentEmergencyRoomPhysiciansTrust from '@/components/ui/ierp/components/sections/IndependentEmergencyRoomPhysiciansTrust'
import DistributionAmounts from '@/components/ui/ierp/components/sections/DistributionAmounts'
import ClaimholderSignature from '@/components/ui/ierp/components/sections/ClaimholderSignature'
import AttachmentA from '@/components/ui/ierp/components/sections/AttachmentA'
import AttachmentB from '@/components/ui/ierp/components/sections/AttachmentB'
import AttachmentC from '@/components/ui/ierp/components/sections/AttachmentC'
import ConfirmationDialog from '@/components/ui/ierp/components/controls/ConfirmationDialog'
import LoggedInFooter from '@/components/ui/ierp/components/controls/LoggedInFooter'

export default {
  name: 'AbatementDistribution',

  components: {
    LoginDialog,
    IndependentEmergencyRoomPhysiciansTrust,
    DistributionAmounts,
    ClaimholderSignature,
    AttachmentA,
    AttachmentB,
    AttachmentC,
    ConfirmationDialog,
    LoggedInFooter
  },

  data: () => ({
    disableCount: 0,
    options: {
      itemsPerPage: -1
    }
  }),

  computed: {
    userAuthState () {
      let authState = ''

      if (this.$store.state.user.users !== null) {
        if ({}.hasOwnProperty.call(this.$store.state.user.users, 'authState')) {
          authState = this.$store.state.user.users.authState
        }
      }

      return authState
    },
    disableSubmit () {
      let disabled = true

      if (this.disableCount >= 0) {
        if (this.$refs.ierpTrust) {
          disabled = this.$refs.ierpTrust.hasError()
        }

        if (!disabled && this.$refs.distributionAmounts) {
          disabled = this.$refs.distributionAmounts.hasError()
        }

        if (!disabled && this.$refs.claimholderSignature) {
          disabled = this.$refs.claimholderSignature.hasError()
        }

        if (!disabled && this.$refs.attachmentB) {
          disabled = this.$refs.attachmentB.hasError()
        }

        if (!disabled && this.$refs.attachmentC) {
          disabled = this.$refs.attachmentC.hasError()
        }
      }

      return this.displayOnly || disabled
    },
    displayAsSingular () {
      return this.$store.state.abatementDistribution.displayAsSingular
    },
    users () {
      return this.$store.state.user.users
    },
    individualData () {
      return this.$store.state.individual.data
    },
    displayOnly () {
      return this.individualData.step_2_claim_status === ClaimStatus.PENDING_APPROVAL
    }
  },

  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'user/successfulLogin':
        this.initialize()
        break
      case 'individual/push2Data':
        // Now that we know we have the individual, go get the individual's form progress
        this.$store.dispatch('abatementDistribution/retrieve')
        break
      case 'abatementDistribution/push2Data':
        if ({}.hasOwnProperty.call(mutation.payload, 'dispatchCreate')) {
          if (mutation.payload.dispatchCreate) {
            this.$store.dispatch('abatementDistribution/create')
          }
        }

        this.refreshDisable()

        break
      default:
        break
      }
    })
  },

  beforeDestroy () {
    this.unsubscribe()
  },

  methods: {
    initialize () {
      this.$store.dispatch('individual/retrieve')
    },

    refreshDisable () {
      this.disableCount++
    },

    async generatePDF () {
      window.print()
    },

    submit_onClick () {
      this.$refs.submitConfirmationDialog.setDisplayDialog(true)
    },

    async confirm_onClick () {
      this.$store.dispatch('individual/submit')
    }
  }
}
</script>
