import { DataStore } from '@aws-amplify/datastore'
import { ClaimStatus, IndividualVoter } from '@/models'
import configs from '@/configs'
import router from '@/router'

export default {
  init: ({ commit }) => {
    commit('init')
  },

  retrieveAll: async ({ commit, state }) => {
    const thisAction = `Retrieve All ${state.displayAsPlural}`
    let individuals

    commit('setIsBusy', true)

    try {
      individuals = await DataStore.query(IndividualVoter)

      commit('push2All', individuals)
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
      commit('setIsBusy', false)
    }
  },

  retrieve: async ({ commit, rootState, state }) => {
    const thisAction = `Retrieve ${state.displayAsSingular}`
    let individuals
    let rerouteToAdminView = true

    if (rootState.user.users) {
      if ({}.hasOwnProperty.call(rootState.user.users, 'email')) {
        if (rootState.user.users.email) {
          if (rootState.user.users.email.trim().length > 0) {
            rerouteToAdminView = false
            commit('setIsBusy', true)

            try {
              // TODO: Why is the Datastore.query not filtering?
              individuals = await DataStore.query(IndividualVoter, (data) => {
                data.email('eq', rootState.user.users.email)
              })

              commit('push2Data', { data: individuals, email: rootState.user.users.email })
            } catch (ex) {
              // TODO: Log and gracefully report error to user
              console.error(`${thisAction} failed`, ex)
              commit('setIsBusy', false)
            }
          }
        }
      }
    }

    if (rerouteToAdminView) {
      await router.push({ name: 'Admin View' })
    }
  },

  update: async ({ commit, dispatch, rootState, state }) => {
    const thisAction = `Update ${state.displayAsSingular}`

    commit('setIsBusy', true)

    try {
      const original = await DataStore.query(IndividualVoter, state.data.id)

      await DataStore.save(
        IndividualVoter.copyOf(original, (updated) => {
          updated.step_2_claim_status = ClaimStatus.IN_PROCESS,
          updated.guid = rootState.user.users.guid
        })
      )

      dispatch('retrieve')
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
      commit('setIsBusy', false)
    }
  },

  submit: async ({ commit, dispatch, rootState, state }) => {
    const thisAction = `Submit ${state.displayAsSingular}`

    commit('setIsBusy', true)

    try {
      const original = await DataStore.query(IndividualVoter, state.data.id)

      await DataStore.save(
        IndividualVoter.copyOf(original, (updated) => {
          updated.step_2_claim_status = ClaimStatus.PENDING_APPROVAL
        })
      )

      const toast_success = {
        title: 'Step 2 Submitted',
        messages: [
          `Thank you for submitting your Step 2 - ${rootState.abatementDistribution.displayAsSingular} Form.`,
          'We will be reviewing applications, after all documentation has been received, over the next 90 days of the process and be in touch with you as soon as possible.',
          `You can review our Frequently Asked Questions and if you have any questions in the meantime, please contact us at ${configs.product.email_claims}`
        ],
        timeout: 30000,
        variant: 'success'
      }

      dispatch('user/showToast', toast_success, { root: true })

      dispatch('retrieve')
    } catch (ex) {
      // TODO: Log and gracefully report error to user
      console.error(`${thisAction} failed`, ex)
      commit('setIsBusy', false)
    }
  }
}
