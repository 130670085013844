export default {
  init: ({ commit }) => {
    commit('init')
  },

  setAuth: async ({ commit, dispatch, state }, payload) => {
    const thisAction = 'Set Auth'
    let groups = []

    if (payload.authData) {
      if ({}.hasOwnProperty.call(payload.authData, 'signInUserSession')) {
        groups = payload.authData.signInUserSession.accessToken.payload['cognito:groups']
      }
    }

    switch (payload.authState) {
    case 'signedin':
      if (groups) {
        // Setup Cognito Groups
        for (let i = 0; i < groups.length; i++) {
          switch (groups[i]) {
          case 'ierptrust_admins':
            state.users.isAdmin = true
            dispatch('individual/retrieveAll', null, { root: true })
            break
          default:
            break
          }
        }
      }

      // Don't commit Successful Login more than once
      if (state.users !== null) {
        if ({}.hasOwnProperty.call(state.users, 'authState')) {
          if (state.users.authState !== 'signedin') {
            commit('successfulLogin', payload)
          }
        }
      }

      break
    case 'signedout':
      await commit('init')

      dispatch('individual/init', null, { root: true })
      dispatch('abatementDistribution/init', null, { root: true })

      commit('invalidateSession', payload.authState)

      break
    default:
      break
    }
  },

  setUser: ({ commit }, payload) => {
    const thisAction = 'Set User'

    commit('setUser', payload)
  },

  showAlert: ({ commit }, payload) => {
    commit('showAlert', payload)
  },

  showToast: ({ commit }, payload) => {
    commit('showToast', payload)
  }
}
