import { DataStore } from '@aws-amplify/datastore'

export default {
  init: (state) => {
    state.users = { ...state.empty }
  },

  setUser: (state, payload) => {
    if (payload) {
      if ({}.hasOwnProperty.call(payload, 'email')) {
        state.users.email = payload.email
      }

      if ({}.hasOwnProperty.call(payload, 'guid')) {
        state.users.guid = payload.guid
      }
    }
  },

  showAlert: (state, payload) => {},

  showToast: (state, payload) => {},

  invalidateSession: async (state, authState) => {
    await DataStore.stop()

    state.users.authState = authState

    localStorage.clear()

    await DataStore.start()
  },

  successfulLogin: (state, payload) => {
    state.users.authState = payload.authState

    if (state.users.isAdmin) {
      state.users.email = null
      state.users.guid = null
    } else {
      if (payload.authData) {
        if ({}.hasOwnProperty.call(payload.authData, 'attributes')) {
          state.users.email = payload.authData.attributes.email
        }

        if ({}.hasOwnProperty.call(payload.authData, 'username')) {
          state.users.guid = payload.authData.username
        }
      }
    }
  }
}
