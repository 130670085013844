<template>
  <v-sheet id="documents">
    <v-container class="py-5 py-lg-8">
      <v-row class="mt-4">
        <v-col
          v-for="(item, i) in docs"
          :key="i"
          cols="8"
          lg="8"
        >
          <a :href="item.href" class="text-h6 secondary--text mt-1">
            {{ item.link }}
          </a>
        </v-col>
        <!-- ToDo: Add a second column for the first row only and drop in opiods image below-->
      </v-row>

    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    docs: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>
.opiods_image {
  background-image: url('../../../../assets/images/opiods.jpg');
  width: 1200px;
  height: 400px;
  background-position: top;
  background-size: auto;
  text-align: center;
}
</style>
