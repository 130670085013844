<template>
  <div>
    <!-- Title Row -->
    <v-row
      no-gutters
      class="d-print-block"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center pb-1"
      >
        <h3>Data Required to Determine Distribution Amounts</h3>
      </v-col>
    </v-row>
    <!-- Distribution Amounts: User Entry -->
    <v-row
      no-gutters
      class="d-print-none"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-center"
      >
        <distribution-amounts-table
          ref="distributionAmountsTable"
          @change="onChange"
        />
      </v-col>
    </v-row>
    <!-- Distribution Amounts: Print -->
    <print-distribution-amounts-table />
    <!-- Proof -->
    <v-row
      no-gutters
      class="d-print-block pt-1"
      style="page-break-after: avoid;"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left"
      >
        <v-card
          elevation="1"
          class="pa-1"
        >
          <div class="font-italic">
            Provide proof you were employed as an Independent Emergency Room Physician<sup>2</sup> for each year you
            placed an X in the above chart <u>as well as</u> proof of the approximate hours worked each of those years.
          </div>
          <div class="font-italic pt-1">
            Examples of acceptable proof that you were employed as an Independent Emergency Room physician
            can be either of the following: 1) a copy of the 1099 form for each year employed as an
            independent ER physician, or 2) a copy of the independent ER physician's contract with the
            business staffing the emergency room for each year employed as an independent emergency room physician.
          </div>
          <div class="font-italic pt-1">
            Acceptable proof of hours worked each year is work schedules or pay records. For the year(s) you
            do not have said proof, fill out the affidavit attached as <a href="#attachment-B">Attachment B</a>.
          </div>
          <div class="font-italic pt-1">
            Please note: Even if in the initial eligibility process, you already provided any documents described as
            "acceptable proof" above, you must resubmit with this document.
          </div>
          <h3 class="text-center">Acceptable Proof Documents should be ordered by year.</h3>
        </v-card>
      </v-col>
    </v-row>
    <!-- Fine Print #2 -->
    <v-divider class="mt-1" style="page-break-after: avoid;" />
    <v-row
      no-gutters
      style="page-break-after: always;"
      class="d-print-block"
    >
      <v-col
        cols="12"
        sm="12"
        class="text-left pt-1"
      >
        <span class="fine-print">
          <sup>2</sup> For purposes of this form and the Trust, Independent Emergency Room Physician
          is defined as an Emergency Room Physician that was a non-hospital employee that worked
          in an emergency room as defined by EMPALA anytime during the years 2001-2019.
          Only provide the information requested below for the years that you worked as an Independent Emergency Room Physician.
          If you worked as an employee of a hospital for any of the years requested below
          do not provide information for that year and place a N/A in the first box.
        </span>
      </v-col>
    </v-row>
    <v-divider class="mt-3 mb-3 d-print-none" />
  </div>
</template>

<script>
import DistributionAmountsTable from '@/components/ui/ierp/components/tables/DistributionAmountsTable'
import PrintDistributionAmountsTable from '@/components/ui/ierp/components/tables/PrintDistributionAmountsTable'

export default {
  name: 'DistributionAmounts',

  components: {
    DistributionAmountsTable,
    PrintDistributionAmountsTable
  },

  methods: {
    onChange (item) {
      this.$nextTick(() => {
        this.$emit('change', item)
      })
    },

    hasError () {
      return this.$refs.distributionAmountsTable.hasError()
    }
  }
}
</script>

<style scoped>

</style>
